import React, { Component } from "react";
import moreResponsability from "../../../img/icons/more-responsability.svg";
import moreMobility from "../../../img/icons/more-mobility.svg";
import follow from "../../../img/icons/follow.svg";
import smartphone from "../../../img/icons/smartphone.svg";

export default class TalentNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  labelSwitch(label) {
    switch (label) {
      case "0":
        return;
      case "1":
        return (
          <div className="tag-of-talent">
            <img src={moreResponsability} alt="Plus de responsabilités" />
            <p>Plus de responsabilités</p>
          </div>
        );
      case "2":
        return (
          <div className="tag-of-talent">
            <img src={moreMobility} alt="Plus de responsabilités" />
            <p>Plus de mobilité</p>
          </div>
        );
      case "3":
        return (
          <div className="tag-of-talent">
            <img src={follow} alt="Plus de responsabilités" />
            <p>À suivre de près</p>
          </div>
        );

      default:
        return;
    }
  }

  render() {
    return (
      <div id="talent-navbar-container">
        <div className="talent-container">
          <div>
            <img
              src={process.env.REACT_APP_API + "/storage/" + this.props.picture}
              alt="talent name"
              className="picture-of-talent"
            />
          </div>
        </div>

        {this.labelSwitch(this.props.label)}
        <div className="navbar_of_showtalent">
          <p>
            <a href="#skills">COMPÉTENCES</a>
          </p>
          <p>
            <a href="#projets">PROJETS & ATTENTES</a>
          </p>
          <p>
            <a href="#notes">NOTES</a>
          </p>
          <p>
            <a href="#successors">SUCCESSIONS</a>
          </p>
        </div>
        <div className="secondary_information_showtalent">
          <img src={smartphone} alt="talent icon phone number" />
          <p>{this.props.num}</p>
        </div>
      </div>
    );
  }
}
