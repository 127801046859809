import React, { Component } from "react";
import NavBar from "../../Navbar/Navbar";
import TalentForm from "./TalentForm";
import ToolsBar from "../../Admin/ToolsBar";
import Loader from "../../Loader/Loader";

const tools = require('../../../fct-tools');
export default class TalentFormGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: false
    }
  }

  componentDidMount(){
    tools.getRole.then((res)=>{
      if(res == process.env.REACT_APP_VIEWER) window.location.href = './'
      else this.setState({auth: true})
    });
  }

  render() {
    if(this.state.auth === false) {
      return(
        <Loader/>
      )
    }
    return (
      <section>
        <NavBar />
        <ToolsBar />
        <TalentForm />
      </section>
    );
  }
}
