import React, { Component } from "react";
import "../../../style/account/navbar.css";
const tools = require("../../../fct-tools");
export default class NavbarAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: 2,
    };
  }

  componentDidMount() {
    tools.getRole.then((res) => {
      this.setState({ auth: res });
    });
  }

  render() {
    console.log("auth -> ", this.state.auth);
    if (this.state.auth == 0) {
      return (
        <div className="navbar-profile-container">
          <nav className="navbar-profile">
            <ul>
              <li className={this.props.activePage === "profile" && "active"}>
                <a href="/profile">Profil</a>
              </li>
              <li className={this.props.activePage === "add-user" && "active"}>
                <a href="/add-user">Ajouter un Utilisateur</a>
              </li>
              <li
                className={this.props.activePage === "users-list" && "active"}
              >
                <a href="/users-list">Liste des utilisateurs</a>
              </li>
              {/* <li>
              <a href="/setting">Paramètres</a>
            </li>
            <li>
              <a href="/custom">Personnalisation</a>
            </li> */}
              <hr />
              <li className="logout-section">
                <a href="/logout">Déconnexion</a>
              </li>
            </ul>
          </nav>
          <ul className="legals-link">
            <li>
              <a href="/">Copyright © 2021 talents-view.com</a>
            </li>
            <li>
              <a href="/">Mentions légales</a>
            </li>
            <li>
              <a href="/">Politique de confidentialité</a>
            </li>
          </ul>
        </div>
      );
    }
    return (
      <div className="navbar-profile-container">
        <nav className="navbar-profile">
          <ul>
            <li className={this.props.activePage === "profile" && "active"}>
              <a href="/profile">Profil</a>
            </li>
            <hr />
            <li className="logout-section">
              <a href="/logout">Déconnexion</a>
            </li>
          </ul>
        </nav>
        <ul className="legals-link">
          <li>
            <a href="/">Copyright © 2021 talents-view.com</a>
          </li>
          <li>
            <a href="/">Mentions légales</a>
          </li>
          <li>
            <a href="/">Politique de confidentialité</a>
          </li>
        </ul>
      </div>
    );
  }
}
