import React, { Component } from "react";
import "../../../style/talent/add-form.css";

const tools = require("../../../fct-tools");
const axios = require("axios").default;
const token = tools.getToken();
export default class TalentToEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      active_selection: "",
      name: "",
      description: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);

    axios
      .post(process.env.REACT_APP_API + "/logged/add-entity", formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => error);
  };

  componentDidMount() {
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API + "/logged/show-entities",
    })
      .then((data) => {
        this.setState({ entities: data.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (this.state.entities.length === 0) {
      return <div>Vous n'avez pas d'entité</div>;
    }
    return (
      // Inputs block
      <section id="tab-talent-add">
        <div id="talent-form">
          <section className="talent-card-img">
            <h3>Ajouter un Talent à une Entité</h3>
          </section>
          {this.state.entities.map((entity) => (
            <div>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ active_selection: entity.id });
                }}
              >
                {entity.name}
              </a>
            </div>
          ))}
          <section id="card-1" className="card"></section>
        </div>
      </section>
    );
  }
}
