import React, { Component } from "react";
import "../../../style/talent/add-talent-form.css";

const tools = require("../../../fct-tools");
const axios = require("axios").default;
const token = tools.getToken();
export default class AddEntity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    if (this.state.name.length > 1 && this.state.description.length > 1) {
      axios
        .post(process.env.REACT_APP_API + "/logged/add-entity", formData, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          window.location.href = "/";
        })
        .catch((error) => error);
    }
  };

  componentDidMount() {}
  render() {
    console.log(this.state.projects);
    console.log("id de l'entité -> ", this.state.select_entity_id);

    return (
      <section id="talent-add-container" className="container">
        <div className="breadcrumb-block">
          <div className="breadcrumb">
            <h1>
              création
              <br />
              d'une entité
            </h1>
            <h2 id="form-h-personnal-informations" className="done">
              Information
              <br />
              de l'entité
            </h2>
            <div
              id="form-barre-personnal-informations"
              className="barre-progress done"
            ></div>

            <h2 id="form-h-validation in-progress">validation</h2>
          </div>
        </div>
        <div className="talent-form-container">
          <a href="/" className="back-btn">
            <i className="icon">&#xe916;</i>Retour
          </a>
          <h2>Information de l'entité</h2>
          <form onSubmit={this.onSubmit} encType="multipart/form-data">
            {/* --------- INFORMATION PERSONNELLE --------- */}
            <div className="form-section" id="personnal-informations">
              <div className="left-section">
                <div className="one-col">
                  <label htmlFor="name">Nom de l'entité*</label>
                  <input
                    name="name"
                    onChange={this.onChange}
                    type="text"
                    id="name"
                    placeholder="Nom de l'entité"
                  />
                </div>
                <div className="one-col">
                  <label htmlFor="description">Description*</label>
                  <textarea
                    id="description"
                    onChange={this.onChange}
                    placeholder="Description"
                    type="text"
                    name="description"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="next-page-form">
              <button id="submit-form-talent">Valider</button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}
