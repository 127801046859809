import React, { Component } from "react";
import NavBar from "../../Navbar/Navbar";
import Form from "./TalentToEntityForm";
/* import "../../../style/login/login.css"; */

export default class AddTalentToEntity extends Component {
  render() {
    return (
      <section>
        <NavBar></NavBar>
        <Form></Form>
      </section>
    );
  }
}
