import React, { Component } from "react";
import $ from "jquery";
import Cookies from "universal-cookie";
import ReCAPTCHA from "react-google-recaptcha";

const cookies = new Cookies();
const axios = require("axios").default;
const recaptchaRef = React.createRef();

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "E-mail",
      password: "Mot de passe",
      error: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onClick = (e) => {
    setTimeout(() => {
      if (this.state.error) {
        $(".connect-input")
          .removeClass("input-login")
          .addClass("input-login-error");
        $("#connect-form").append(
          `<div class="danger-card" style="display: none;">Email ou mot de passe incorrect</div>`
        );
        $(".danger-card").slideDown(500);
        $(e.target).removeClass("disabled-link");
      }
    }, 500);
  };
  componentDidMount() {}
  onSubmit = (e) => {
    e.preventDefault();
    const token = recaptchaRef.current.getValue();
    console.log(token);
    let email = this.state.email;
    let password = this.state.password;
    axios
      .post(
        `https://www.google.com/recaptcha/api/siteverify?secret=6LdfWnEaAAAAAGPHePM2my_Lr3lpf7FZW4QsFEFB&response=${token}`
      )
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .post(
        process.env.REACT_APP_API + "/login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        console.log(data);
        if (data.data.message === "verified") {
          cookies.set("connect_tid", data.data.token, {
            path: "/",
            maxAge: 1440 * 60,
            secure: false, //<----------------- A CHANGER POUR LE HTTPS
            //httpOnly: true
          });
          window.location.href = "/";
        } else if (data.data === "failed") {
          this.setState({ error: true });
        }
      })
      .catch((error) => {
        this.setState({ error: true });
        console.log(error);
      });
  };
  render() {
    return (
      // Inputs block
      <div className="login-form">
        <form onSubmit={this.onSubmit} id="connect-form">
          <h3>Connectez-vous</h3>
          <p className="login-subtitle">
            ou <a href="/register">inscrivez-vous</a>
          </p>
          <input
            placeholder="E-mail"
            type="email"
            name="email"
            className="connect-input input-login"
            onChange={this.onChange}
            id="email"
          />
          <input
            placeholder="Mot de passe"
            type="password"
            name="password"
            className="connect-input input-login"
            onChange={this.onChange}
            id="password"
          />
          <p className="recaptcha-info">
            Cette page protégée par un dispositif reCAPTCHA est soumise aux
            règles de confidentialité et aux conditions d’utilisation de Google.
          </p>
          <br />
          {() => {
            if (this.state.error) {
              <div>aie...</div>;
            }
          }}
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdfWnEaAAAAAI9q0W9J7jUBvcHkferPYL0rs8nW"
            onChange={this.onChange}
          />
          <button onClick={this.onClick} type="submit" className="btn-login">
            Se connecter
          </button>
        </form>
      </div>
    );
  }
}
