import React, { Component } from "react";
import "../../../style/talent/notFoundTalents.css";

export default class NotFoundTalents extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <div className="container" id="notFoundTalents">
        <div>
          <h1>Aucun talent à afficher.</h1>
          <p className="t-center">
            <a href="/add-talent" className="primary-action-btn">
              Ajouter un talent
            </a>
          </p>
        </div>
      </div>
    );
  }
}
