import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import moreResponsability from "../../../../img/icons/more-responsability.svg";
import moreMobility from "../../../../img/icons/more-mobility.svg";
import follow from "../../../../img/icons/follow.svg";
const tools = require("../../../../fct-tools");
const token = tools.getToken();

export default class TalentSuccessors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      talents: null,
      search_successor: "1",
    };
  }

  componentDidMount() {
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API + "/logged/show-talents/",
    })
      .then((data) => {
        console.log("data ->", data.data);
        this.setState({ talents: data.data });
      })
      .catch((err) => {
        console.error(err);
      });
    $("#2").hide();
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sort = (array) => {
    let id_list = "";
    const talents_sorted = [];
    const talents = this.state.talents;
    for (let i = 0; i < array.length; i++) {
      id_list = id_list.toString() + "|" + array[i].talent_id.toString();
    }

    for (let j = 0; j < talents.length; j++) {
      if (id_list.includes(talents[j].id.toString()))
        talents_sorted.push(talents[j]);
    }
    return talents_sorted;
  };
  labelSwitch(label) {
    switch (label) {
      case "0":
        return;
      case "1":
        return (
          <img
            src={moreResponsability}
            className="label-of-talent"
            alt="Plus de responsabilités"
          />
        );
      case "2":
        return (
          <img
            src={moreMobility}
            alt="Plus de responsabilités"
            className="label-of-talent"
          />
        );
      case "3":
        return (
          <img
            src={follow}
            alt="Plus de responsabilités"
            className="label-of-talent"
          />
        );

      default:
        return;
    }
  }

  render() {
    let isDisplay = [];
    let talents_succeed_sorted = [];
    let talents_may_succeed_sorted = [];

    if (
      this.props.succeed === "undefined" ||
      this.props.succeed == null ||
      this.props.succeed.length === 0
    ) {
      return (
        <section id="successors">
          <h2>successions</h2>
          <div id="select-option">
            <select name="search-successor">
              <option value="1">Peut succéder à</option>
              <option value="2">A pour successeurs potentiels</option>
            </select>
          </div>
          <div>Pas de successeurs ajoutés.</div>
        </section>
      );
    }

    const succeed = JSON.parse(this.props.succeed);
    const may_succeed = JSON.parse(this.props.may_succeed);

    if (this.state.talents == null && this.props.succeed !== "undefined") {
      return <div>Loading...</div>;
    }

    talents_succeed_sorted = this.sort(succeed);
    talents_may_succeed_sorted = this.sort(may_succeed);

    console.log("succeed ->", talents_succeed_sorted);
    console.log("may succeed ->", talents_may_succeed_sorted);

    if (this.state.search_successor == 1) {
      isDisplay = talents_succeed_sorted;
    } else if (this.state.search_successor == 2) {
      isDisplay = talents_may_succeed_sorted;
    }

    console.log("succeed ->", isDisplay);

    return (
      <section id="successors">
        <h2>successions</h2>
        <div id="select-option">
          <select onChange={this.onChange} name="search_successor">
            <option value="1">Peut succéder à</option>
            <option value="2">A pour successeurs potentiels</option>
          </select>
        </div>
        <div id="successor-container">
          {isDisplay.map((succeed) => [
            <div id="1" className="talent-container">
              <div>
                {this.labelSwitch(succeed.label)}
                <img
                  className="picture-of-talent"
                  src={
                    process.env.REACT_APP_API + "/storage/" + succeed.url_pic
                  }
                  alt="fake img"
                />
              </div>
              <div>
                <h3>{succeed.nine_box}</h3>
                <h4>
                  {succeed.first_name} {succeed.last_name}
                </h4>
                <h5>{succeed.job}</h5>
              </div>
            </div>,
          ])}
        </div>
      </section>
    );
  }
}
