import React, { Component } from "react";

export default class TalentCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let course = [];
    let formations = [];
    let studies = [];
    let language = [];

    if (this.props.course.length > 1) {
      course = JSON.parse(this.props.course);
    }
    if (this.props.formations.length > 1) {
      formations = JSON.parse(this.props.formations);
    }
    if (this.props.studies.length > 1) {
      studies = JSON.parse(this.props.studies);
    }
    if (this.props.language !== "undefined") {
      if (this.props.language.length > 1) {
        language = JSON.parse(this.props.language);
      }
    }

    return (
      <div id="talent-course-container">
        <div id="languages-showtalent">
          <h2>langues</h2>
          {language.map((step) => (
            <img
              src={process.env.REACT_APP_API + "/flag/" + step.code + ".svg"}
              alt={step.code + " flag"}
            />
          ))}
        </div>
        <div id="course-showtalent">
          <h2>Parcours</h2>
          {course.map((step) => (
            <div>
              <h4>
                {step.date_start} / {step.date_end}
              </h4>
              <p>
                <strong>{step.title}</strong> <br />
                {step.description}
              </p>
            </div>
          ))}
        </div>
        <div id="formation-showtalent">
          <h2>Formations pro</h2>
          {formations.map((step) => (
            <h4>
              {step.date} : {step.name}
            </h4>
          ))}
        </div>
        <div id="school-showtalent">
          <h2>Etudes</h2>
          {studies.map((step) => (
            <div>
              <h4>
                {step.date} : {step.school_name}
              </h4>
              <p>{step.school_formation_name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
