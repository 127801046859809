import React, { Component } from "react";

export default class TalentProjects extends Component {
  render() {
    let project = [];
    if (this.props.projects.length > 1) {
      project = JSON.parse(this.props.projects);
    }
    return (
      <section id="projets">
        <h2 className="title-of-section">Projets & attentes</h2>
        <ul>
          {project.map((step) => (
            <li>{step.description}</li>
          ))}
        </ul>
      </section>
    );
  }
}
