import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import axios from "axios"
import Home from "./component/Home/Home";
import Login from "./component/Login/LoginPage";
import Logout from "./component/Logout/Logout";
import Register from "./component/Register/RegisterPage";
import Talent from "./component/Talent/ShowTalent/Talent";
import AddTalent from "./component/Talent/addTalent/TalentFormGlobal";
import AddEntity from "./component/Entity/add-entity/EntityPage";
import AddTalentToEntity from "./component/Talent/add-talent-to-entity/TalentToEntityPage";
import Editor from "./component/Admin/Editor";
import EditTalent from "./component/Talent/EditTalent/TalentFormGlobalEdit";
import Statistical from "./component/Statistical/Statistical";

import Profile from "./component/Account/Profile/Profile";
import UsersList from "./component/Account/UsersList/UsersList";
import AddUser from "./component/Account/AddUser/AddUser";

//import Navbar from './component/Navbar';
import "./style/reset.css";
import "./App.css";
import { render } from "@testing-library/react";

export default class App extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount () {}

  render() {
    return (
      <section>
        <BrowserRouter>
          <Router>
            <Switch>
              <Route path="/agencerjsdev" component={Register}></Route>
              <Route path="/login" component={Login}></Route>
              <Route path="/logout" component={Logout}></Route>
              <Route path="/talent/:id" component={Talent}></Route>
              <Route path="/edition/:id" component={EditTalent}></Route>
              <Route path="/statistical" component={Statistical}></Route>
              <Route path="/add-entity" component={AddEntity}></Route>
              <Route path="/add-talent" component={AddTalent}></Route>
              <Route path="/profile" component={Profile}></Route>
              <Route path="/users-list" component={UsersList}></Route>
              <Route path="/add-user" component={AddUser}></Route>
              <Route exact path="/editor" component={Editor}></Route>
              <Route path="/" component={Home}></Route>
            </Switch>
          </Router>
        </BrowserRouter>
      </section>
    );
  }

}
