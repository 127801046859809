import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Navbar from "../../Navbar/Navbar";
import Loader from "../../Loader/Loader";
import ToolsBar from "../../Admin/ToolsBar";

import NavbarAccount from "../Navbar/NavbarAccount";

import "../../../style/account/users-list.css";
const tools = require("../../../fct-tools");
const token = tools.getToken();

export default class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key_user: "",
      users: [""],
      isConnect: false,
    };
  }

  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API + "/is-logged", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        this.setState({
          key_user: result.data.user.payload.key,
          isConnect: true,
        });
        axios
          .post(
            process.env.REACT_APP_API + "/logged/get-my-users",
            { key: this.state.key_user },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            this.setState({
              users: res.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        //window.location.href = "/login";
      });
  }

  delete = (e, id) => {
    e.preventDefault();
    console.log(id);
    axios
      .post(
        process.env.REACT_APP_API + `/logged/delete-user`,
        {
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log("done");
        window.location.reload();
        /*         this.fetchData();
        this.setState({
          idTalentToDelete: "",
          pictureTalentToDelete: "",
          nameTalentToDelete: "", */
      })
      .catch((error) => error);
  };

  render() {
    if (this.state.users < 0) {
      return <Loader />;
    }
    return (
      <div>
        <Navbar />
        <ToolsBar />
        <div className="container d-flex account-container">
          <NavbarAccount activePage="users-list" />
          <div className="account-block">
            <h1>Liste des utilisateurs</h1>
            <div className="row-section-account">
              <div className="col-12">
                <table className="users-list">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.map((user) => (
                      <tr>
                        <td>{user.id}</td>
                        <td>
                          {user.first_name} {user.last_name}
                        </td>
                        <td>
                          <button
                            onClick={(e) => this.delete(e, user.id)}
                            className="delete-btn"
                          >
                            <i className="icon">&#xe917;</i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
