import React, { Component } from "react";
import logo_loader from "../../img/loader_logo.svg";

export default class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <section id="loader-container">
        <div>
          <div className="loader-animation">
            <div className="loader-user"></div>
            <div className="loader-flash"></div>
          </div>
          <img src={logo_loader} alt="logo" />
        </div>
      </section>
    );
  }
}
