import React, { Component } from "react";
import axios from "axios";
import "../../style/navbar/navbar.css";
import "../../style/navbar/menu-burger-anim.css";
import logo from "../../img/logo-white.png";
import pictureAccount from "../../img/account.svg";
import $ from "jquery";
const tools = require("../../fct-tools");
const token = tools.getToken();

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: localStorage.getItem("username"),
      role: "",
    };
  }
  componentDidMount() {
    $("#menu-nav-burger").click(() => {
      $("#menu-nav-burger").toggleClass("navbar-mobile-button-active");
      $("#menu-burger-container").html(`
      <ul>
          <li className="burger-item"><a href="/successor">Plan de successions</a></li>
          <li className="burger-item"><a href="/">Talent management</a></li>
          <li className="burger-item"><a href="/statistical">Statistiques</a></li>
          <li className="burger-item"><a href="/profile">${this.state.username}</a></li>
      </ul>
      `);
      $("#burger").slideToggle();
    });

    $("#userSetting").click(() => {
      $("#dropdownSetting").toggleClass("dropdown-setting-active");
      console.log("ouvert");
    });
  }
  render() {
    return (
      <section>
        <div className="nav-body">
          <a href="/">
            <img id="logo-burger" src={logo} alt="logo-default" />
          </a>
          <nav className="desktop-render">
            <ul>
              <li>
                <a href="/successor">Plan de successions</a>
              </li>
              <li>
                <a href="/">Talent management</a>
              </li>
              <li>
                <a href="/statistical">Statistiques</a>
              </li>
              <li id="account-item">
                <ul className="dropdown-setting" id="dropdownSetting">
                  <li>
                    <p>
                      <a href="/editor">Mode éditeur</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a href="/profile">Paramètres</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      <a href="/logout">Déconnexion</a>
                    </p>
                  </li>
                </ul>
                <div>
                  <img src={pictureAccount} alt="pp-user" />
                  <a href="/profile">{this.state.username}</a>
                </div>
                <button id="userSetting">
                  <i className="icon">&#xe90c;</i>
                </button>
              </li>
            </ul>
          </nav>

          <nav id="respons-menu">
            <div id="menu-nav-burger" className="navbar-mobile-button">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </nav>
        </div>
        <div id="burger">
          <div id="menu-burger-container"></div>
        </div>
      </section>
    );
  }
}
