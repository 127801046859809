import React, { Component } from "react";
import axios from 'axios';
const tools = require("../../fct-tools");

const token = tools.getToken();
const account_data = axios.get(process.env.REACT_APP_API + "/is-logged", {
  headers: { Authorization: `Bearer ${token}` },
});

export default class ToolsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 1
    };
  }
  componentDidMount() {
    axios
    .all([account_data])
    .then((result) => {
      this.setState({
        role: result[0].data.user.payload.role
      })
    })
    .catch((err) => {
      console.log(err);
    });
  }
  render() {
    if(this.state.role !== 0) {
      return(<div></div>)
    }
    return (
      <div id="div_add_t_e">
        <ul className="container">
        <li>
            <p>
              <a href="/add-entity">Ajouter une entité </a>
            </p>
          </li>
          <li>
            <p>
              <a href="/add-talent">Ajouter un talent </a>
            </p>
          </li>
          <li>
            <p>
              <a href="/editor"> Gerer la liste </a>
            </p>
          </li>
        </ul>
      </div>
    );
  }
}
