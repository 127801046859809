import $, { type } from "jquery";
import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getToken = () => {
  const token = cookies.get("connect_tid");
  return token;
};

export const switchFrom = (idOff, idOn, hOff, hOn, barreOff, barreOn) => {
  $("#" + idOff).addClass("form-off");
  $("#" + idOn)
    .removeClass("form-off")
    .fadeIn(500);
  if (hOff !== "") $("#" + hOff).addClass("done");
  $("#" + hOn).addClass("in-progress");
  if (barreOff !== "") {
    $("#" + barreOff).addClass("done");
  }
  if (barreOn !== "" && barreOn) {
    $("#" + barreOn).removeClass("done");
  }
};

export const getIDDataList = (string) => {
  const split1 = string.split("-");
  const split2 = split1[0].split("#");
  const split3 = split2[1].split(" ");
  return Number(split3[0]);
};

export const getNameDataList = (string) => {
  const split1 = string.split("- ");
  return split1[1];
};

export const getRole = new Promise((res, err) => {
  const token = cookies.get("connect_tid");
  axios.get(process.env.REACT_APP_API + "/is-logged", {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then((result) => {
    res(result.data.user.payload.role);
  })
  .catch((err) => {
    res(err);
    console.log(err);
  });
})
