import React, { Component } from "react";
import Loader from "../Loader/Loader";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export default class Logout extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    cookies.remove('connect_tid');
    setTimeout(()=>{
      window.location.href = "/login";
    }, 500);
  }
  render() {
    return <Loader />;
  }
}
