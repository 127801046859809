import React, { Component } from "react";
import Navbar from "../../Navbar/Navbar";
import TalentNavbar from "./TalentNavbar";
import TalentCourse from "./TalentCourse";
import TalentContent from "./TalentContent";
import "../../../style/talent/talentShow/talent.css";
import Loader from "../../Loader/Loader";
import ToolsBar from "../../Admin/ToolsBar";
import axios from "axios";

const tools = require("../../../fct-tools");
const token = tools.getToken();

export default class Talent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      talent_id: this.props.match.params.id,
      data: null,
      entity: null,
    };
  }

  componentDidMount() {
    //console.log(this.state.talent_id);
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url:
        process.env.REACT_APP_API +
        "/logged/show-talent/" +
        this.state.talent_id,
    })
      .then((data) => {
        this.setState({ data: data.data });

        axios({
          method: "GET",
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url:
            process.env.REACT_APP_API +
            "/logged/show-entity/" +
            this.state.data.id_entity,
        })
          .then((res) => {
            this.setState({ entity: res.data });
          })
          .catch((err) => {
            console.error("Entity catching failed... " + err);
          });
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          window.location.href = "/login";
        } else {
        }
      });
  }

  render() {
    if (
      this.state.data === null ||
      this.state.data === [] ||
      this.state.entity === null
    ) {
      return <Loader />;
    }
    return (
      <div>
        <Navbar></Navbar>
        <ToolsBar />
        <div id="talent-page">
          <div id="talent-header-container">
            <div>
              <a href="/" className="back-btn">
                <i className="icon">&#xe916;</i>Retour
              </a>
            </div>
            <div className="switch-talent">
              <a href="/">
                <i className="icon">&#xe900;</i>
              </a>
              <a href="/">
                <i className="icon">&#xe901;</i>
              </a>
            </div>
          </div>
          <div id="talent-global-container">
            <TalentNavbar
              picture={this.state.data.url_pic}
              num={this.state.data.phone}
              label={this.state.data.label}
            ></TalentNavbar>
            <TalentContent
              data={JSON.stringify(this.state.data)}
              entity={this.state.entity.name}
            ></TalentContent>
            <TalentCourse
              course={this.state.data.course}
              formations={this.state.data.formations}
              studies={this.state.data.studies}
              language={this.state.data.language}
            ></TalentCourse>
          </div>
        </div>
      </div>
    );
  }
}
