import React, { Component } from "react";
import loginIllustration from "../../img/login-illustration.png";

export default class Login extends Component {
  render() {
    return (
      // informations block of left
      <div className="login-block-information">
        {/* <img src={logo} /> */}
        <div>
          <img src={loginIllustration} alt="login-illustration" />
          <h1>
            Talent management
            <br />& Plans de successions
          </h1>
          <p>
            Suivez vos talents et aidez-les à se développer au sein de votre
            entreprise. Scénarisez vos plans de succession.
          </p>
          <a href="/">EN SAVOIR PLUS</a>
        </div>
      </div>
    );
  }
}
