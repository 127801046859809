import React, { Component } from "react";
import moment from "moment";

export default class TalentHeader extends Component {
  render() {
    const age =
      Number(moment(new Date()).format("YYYY")) -
      Number(moment(this.props.birthday).format("YYYY"));

    return (
      <section id="header-showtalent">
        <div>
          <h3>{this.props.nineBox}</h3>
          <h2>
            {this.props.first_name} {this.props.last_name}
          </h2>
          <p>{this.props.job}</p>
          <p>
            {age} ans | {this.props.adr}
          </p>
        </div>
        <div>
          <p>{this.props.entity}</p>
        </div>
      </section>
    );
  }
}
