import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Navbar from "../Navbar/Navbar";
import Loader from "../Loader/Loader";
import ToolsBar from "./ToolsBar";
import $ from "jquery";
import "../../style/talent/talent-list.css";

const tools = require("../../fct-tools");
const token = tools.getToken();
export default class TalentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      talents: [],
      entities: [],
      filter: null,
      auth: false,
      nameTalentToDelete: "",
      idTalentToDelete: "",
      pictureTalentToDelete: "",
    };
  }

  syncEntity = (te_id) => {
    if (this.state.entities === undefined) {
      console.log("Loading ...");
    } else {
      for (let i = 0; i < this.state.entities.length; i++) {
        if (this.state.entities[i].id === te_id) {
          return this.state.entities[i].name;
        }
      }
    }
  };

  deleteModal = (e, id, url, name) => {
    e.preventDefault();
    this.setState({
      idTalentToDelete: id,
      pictureTalentToDelete: url,
      nameTalentToDelete: name,
    });
    $("#modalDelete").fadeIn("fast");
  };

  closeDeleteModal = (e) => {
    e.preventDefault();
    let _this = this;
    $("#modalDelete").fadeOut("fast", function () {
      _this.setState({
        idTalentToDelete: "",
        pictureTalentToDelete: "",
        nameTalentToDelete: "",
      });
    });
  };

  deleteTalent = (e, id, url) => {
    e.preventDefault();
    axios
      .delete(
        process.env.REACT_APP_API + `/logged/delete-talent/${id}/${url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        this.fetchData();
        this.setState({
          idTalentToDelete: "",
          pictureTalentToDelete: "",
          nameTalentToDelete: "",
        });
        $("#modalDelete").fadeOut("fast");
      })
      .catch((error) => error);
  };

  showTalent = (e, show, id) => {
    e.preventDefault();
    $("#show-icon").addClass("disabled-link");
    axios
      .put(
        process.env.REACT_APP_API + `/logged/show-toggle`,
        {
          show,
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
        { withCredentials: true }
      )
      .then((response) => {
        //console.log(response);
        this.fetchData();
        setTimeout(() => {
          $("#show-icon").removeClass("disabled-link");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        $("#show-icon").removeClass("disabled-link");
      });
  };

  onFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  fetchData = () => {
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API + "/logged/show-talents-admin",
    })
      .then((data) => {
        this.setState({ talents: data.data });
        axios({
          method: "GET",
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          url: process.env.REACT_APP_API + "/logged/show-entities",
        })
          .then((res) => {
            this.setState({ entities: res.data });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          window.location.href = "/login";
        } else {
          console.log(err);
        }
      });
  };

  componentDidMount() {
    this.fetchData();
    tools.getRole.then((res)=>{
      if(res == process.env.REACT_APP_VIEWER) window.location.href = './'
      else this.setState({auth: true})
    });
    $("#triggerCloseModal").click(function () {
      $("#modalDelete").fadeOut("fast");
    });
  }

  IsDisplay = (props) => {
    const show = props.show;
    const id = props.id;
    if (show) {
      return (
        <a
          id="show-icon"
          onClick={(e) => this.showTalent(e, show, id)}
          href="/"
          className="edit-btn"
        >
          <i className="icon">&#xe91b;</i>
        </a>
      );
    } else {
      return (
        <a
          id="show-icon"
          onClick={(e) => this.showTalent(e, show, id)}
          href="/"
          className="edit-btn"
        >
          <i className="icon">&#xe91c;</i>
        </a>
      );
    }
  };

  render() {
    if (
      this.state.data === null ||
      this.state.data === [] ||
      this.state.entities === null ||
      this.state.auth === false
    ) {
      return <Loader />;
    }
    let talents_sorted = this.state.talents;
    if (this.state.filter != null) {
      talents_sorted = this.state.talents.filter((talent) => {
        let name = talent.first_name + " " + talent.last_name;
        return name.toLowerCase().includes(this.state.filter.toLowerCase());
      });
    }
    return (
      <div>
        <Navbar />
        <ToolsBar />
        <div id="modalDelete">
          <div id="triggerCloseModal"></div>
          <div>
            <h1>Confirmez-vous la suppression ?</h1>
            <p>
              Si vous confirmez, la fiche de
              <strong> {this.state.nameTalentToDelete}</strong> sera
              définitivement effacé.
            </p>
            <div className="action-btn-container">
              <button
                onClick={(e) =>
                  this.deleteTalent(
                    e,
                    this.state.idTalentToDelete,
                    this.state.pictureTalentToDelete
                  )
                }
              >
                Supprimer
              </button>
              <button onClick={(e) => this.closeDeleteModal(e)}>Annuler</button>
            </div>
          </div>
        </div>
        <div className="talentlist-container">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Nom</th>
                <th>Poste occupé</th>
                <th>Entitée et Fillères</th>
                <th>
                  <input
                    onChange={this.onFilterChange}
                    type="search"
                    name="talent"
                    id="searchTalentListe"
                    placeholder="Recherche"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {talents_sorted.map((talent) => (
                <tr>
                  <td>{talent.id}</td>
                  <td>
                    <Link to={`/talent/${talent.id}`} className="talentName">
                      {talent.first_name} {talent.last_name}
                    </Link>
                  </td>
                  <td>{talent.job}</td>
                  <td>{this.syncEntity(talent.id_entity)}</td>
                  <td>
                    <this.IsDisplay show={talent.display} id={talent.id} />
                    <Link to={`/edition/${talent.id}`} className="edit-btn">
                      <i className="icon">&#xe912;</i>
                    </Link>
                    <a
                      onClick={(e) =>
                        this.deleteModal(
                          e,
                          talent.id,
                          talent.url_pic,
                          talent.first_name + " " + talent.last_name
                        )
                      }
                      href="/"
                      className="delete-btn"
                    >
                      <i className="icon">&#xe917;</i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
