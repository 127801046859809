import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Navbar from "../../Navbar/Navbar";
import Loader from "../../Loader/Loader";
import ToolsBar from "../../Admin/ToolsBar";

import NavbarAccount from "../Navbar/NavbarAccount";

import "../../../style/account/profile.css";
const tools = require("../../../fct-tools");
const token = tools.getToken();

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: [""],
      isConnect: false,
    };
  }

  componentDidMount() {
    const account_data = axios.get(process.env.REACT_APP_API + "/is-logged", {
      headers: { Authorization: `Bearer ${token}` },
    });
    axios
      .all([account_data])
      .then((result) => {
        this.setState({
          account: result[0].data.user.payload,
          isConnect: true,
        });
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/login";
      });
  }

  render() {
    if (
      this.state.data === null ||
      this.state.data === [] ||
      this.state.entity === null
    ) {
      return <Loader />;
    }
    return (
      <div>
        <Navbar />
        <ToolsBar />
        <div className="container d-flex account-container">
          <NavbarAccount activePage="profile" />
          <div className="account-block">
            <h1>profile</h1>
            <div className="row-section-account">
              <div className="col-12">
                {this.state.account.role === 0 && (
                  <p id="role">Administrateur</p>
                )}
                <p>
                  <b>Nom: </b>
                  {this.state.account.first_name} {this.state.account.last_name}
                </p>
                <p>
                  <b>Adresse Mail: </b>
                  {this.state.account.email}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
