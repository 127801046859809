import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "../../../style/talent/add-talent-form.css";
import $ from "jquery";
import countryJSON from "../addTalent/country.json";

const moment = require("moment");
const tools = require("../../../fct-tools");
const axios = require("axios").default;
const token = tools.getToken();

export default class TalentFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_card: "personnal-informations",
      name_ac: "Information personnelle",
      entities: [],
      select_entity_id: "1",
      talents: [],
      talent: [],
      select_talent_id: "",
      hard_skills: [],
      soft_skills: [],
      year: [],
      service: "",
      search_hierarchy: "",

      /*--- Personal Information ---*/
      file: "",
      fileName: "",
      first_name: "",
      last_name: "",
      gender: "other",
      birthday: moment().format(),
      phone: "0000000000",
      mail: "",
      address: "",
      add_poste: "",
      ninebox: "",
      label_talent: 0,

      /*--- Professional Course ---*/
      professional_course: [],
      date_poste_start: "0000-00-00",
      date_poste_end: "0000-00-00",
      title_poste: "",
      description_poste: "",
      cgucheck: false,

      /*--- Formations ---*/
      formations: [],
      formation_name: "",
      formation_year: "0000",

      /*--- Studies ---*/
      studies: [],
      school_name: "",
      school_formation_name: "",
      school_date: "0000-00-00",

      /*--- Skills Information ---*/
      total_hard_skills: [],
      search_hardskill: "",
      skill_level: 20,

      total_soft_skills: [],
      search_softskill: "",

      /*--- Project ---*/
      projects: [],
      add_project: "",

      /*--- Note ---*/
      notes: [],
      add_note: "",

      /*--- Succeed ---*/
      search_person_total: [],
      search_person: "",

      /*--- May Succeed ---*/
      search_person_2_total: [],
      search_person_2: "",

      /*--- Languages ---*/
      language_total: [],
      language: "",
    };
  }

  componentDidMount() {
    $("#submit-form-talent").hide();
    const year = Number(moment().format("YYYY"));
    for (let index = year; index > year - 100; index--) {
      let totalYear = this.state.year;
      const yearList = {};
      yearList.year = index;
      totalYear.push(yearList);
      this.setState({
        year: totalYear,
      });
    }

    //ENTITIES get
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API + "/logged/show-entities",
    })
      .then((res) => {
        this.setState({ entities: res.data });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          window.location.href = "/login";
        } else {
        }
      });

    //TALENTS get
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API + "/logged/show-talent-specific",
    })
      .then((res) => {
        this.setState({ talents: res.data });
      })
      .catch((err) => {
        console.log(err);
      });

    //TALENT get
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url:
        process.env.REACT_APP_API +
        `/logged/show-talent/${this.props.talent_id}`,
    })
      .then((res) => {
        console.log(res.data);
        const talent = res.data;
        this.setState({
          talent: talent,
          select_entity_id: talent.id_entity,
          service: talent.service,
          search_hierarchy: talent.hierarchy,

          /*--- Personal Information ---*/
          first_name: talent.first_name,
          last_name: talent.last_name,
          gender: talent.gender,
          birthday: moment(talent.birthday).format("YYYY-MM-DD"),
          phone: talent.phone,
          mail: talent.email,
          address: talent.address,
          add_poste: talent.job,
          ninebox: talent.nine_box,
          label_talent: talent.label,

          /*--- Professional Course ---*/
          professional_course: JSON.parse(talent.course),

          /*--- Formations ---*/
          formations: JSON.parse(talent.formations),

          /*--- Studies ---*/
          studies: JSON.parse(talent.studies),

          /*--- Skills Information ---*/
          total_hard_skills: JSON.parse(talent.hard_skill),
          total_soft_skills: JSON.parse(talent.soft_skill),

          /*--- Project ---*/
          projects: JSON.parse(talent.projects),

          /*--- Note ---*/
          notes: JSON.parse(talent.notes),

          /*--- Succeed ---*/
          search_person_total: JSON.parse(talent.succeed),

          /*--- May Succeed ---*/
          search_person_2_total: JSON.parse(talent.may_succeed),

          /*--- Languages ---*/
          language_total: JSON.parse(talent.language),
        });
      })
      .catch((err) => {
        console.log(err);
      });

    //HARD SKILLS get
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API + "/logged/show_hard_skills",
    })
      .then((res) => {
        if (res.data.length > 0) {
          const data = res.data;
          let tab_hardskill = [];
          let tab_softskill = [];
          for (const i in data) {
            if (data[i].typeof === 0) {
              tab_hardskill.push(data[i]);
            } else if (data[i].typeof === 1) {
              tab_softskill.push(data[i]);
            } else console.log("Issue at " + i);
          }
          this.setState({
            hard_skills: tab_hardskill,
            soft_skills: tab_softskill,
          });
        } else {
          console.log("data skills empty...");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChangeGenre = (e) => {
    this.setState({ gender: e.target.value });
  };
  onChangeFile = (e) => {
    try {
      this.setState({
        fileName: e.target.files[0].name,
        file: e.target.files[0],
      });
    } catch (err) {
      console.error("Error catch ! : ", err);
    }

    const files = e.target.files[0];
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        $("#picture-of-talent-preview").attr("src", this.result);
      });
    }
  };

  changeCard = (e) => {
    e.preventDefault();
    switch (this.state.active_card) {
      /*------------ COURSE -----------*/
      case "personnal-informations":
        tools.switchFrom(
          "personnal-informations",
          "course",
          "form-h-personnal-informations",
          "form-h-course",
          "form-barre-personnal-informations"
        );
        $("#form-h-historique").addClass("done");
        $("#form-barre-historique").addClass("done");
        this.setState({
          active_card: "course",
          name_ac: "Historique",
        });
        break;

      /*------------ FORMATIONS -----------*/
      case "course":
        tools.switchFrom(
          "course",
          "formations",
          "form-h-course",
          "form-h-formations",
          "form-barre-course"
        );
        this.setState({
          active_card: "formations",
        });
        break;

      /*------------ SKILL FORM -----------*/
      case "formations":
        tools.switchFrom(
          "formations",
          "skills-form",
          "form-h-formations",
          "form-h-skills-form",
          "form-barre-formations"
        );
        $("#form-h-description").addClass("done");
        $("#form-barre-description").addClass("done");
        this.setState({
          active_card: "skills-form",
        });
        break;

      /*------------ PROJECT FORM -----------*/
      case "skills-form":
        tools.switchFrom(
          "skills-form",
          "project-form",
          "form-h-skills-form",
          "form-h-project-form",
          "form-barre-skills-form"
        );
        this.setState({
          active_card: "project-form",
          name_ac: "Description",
        });
        break;

      /*------------ SUCCESSOR FORM -----------*/
      case "project-form":
        tools.switchFrom(
          "project-form",
          "successor-form",
          "form-h-project-form",
          "form-h-successor-form",
          "form-barre-project-form"
        );
        this.setState({
          active_card: "successor-form",
        });
        break;

      /*------------ VALIDATION FORM -----------*/
      case "successor-form":
        tools.switchFrom(
          "successor-form",
          "validation-form",
          "form-h-successor-form",
          "form-h-validation",
          "form-barre-successor-form"
        );
        $("#next-form-talent").hide();
        $("#submit-form-talent").show();
        this.setState({
          active_card: "validation-form",
          name_ac: "Validation",
        });
        break;

      /*------------ PERSONAL INFORMATION -----------*/

      default:
        break;
    }
  };

  precedentCard = (e) => {
    e.preventDefault();
    switch (this.state.active_card) {
      /*------------ COURSE -----------*/
      case "personnal-informations":
        tools.switchFrom(
          "personnal-informations",
          "course",
          "form-h-personnal-informations",
          "form-h-course",
          "form-barre-personnal-informations"
        );
        $("#form-h-historique").addClass("done");
        $("#form-barre-historique").addClass("done");
        this.setState({
          active_card: "course",
          name_ac: "Historique",
        });
        break;

      /*------------ FORMATIONS -----------*/
      case "course":
        tools.switchFrom(
          "course",
          "personnal-informations",
          "",
          "form-h-course",
          "",
          "form-barre-personnal-informations"
        );
        $("#form-h-historique").removeClass("done");
        $("#form-barre-historique").removeClass("done");
        this.setState({
          active_card: "personnal-informations",
          name_ac: "Information personnelle",
        });
        break;

      /*------------ SKILL FORM -----------*/
      case "formations":
        tools.switchFrom(
          "formations",
          "course",
          "",
          "form-h-course",
          "",
          "form-barre-course"
        );
        /*  */
        this.setState({
          active_card: "course",
        });
        break;

      /*------------ PROJECT FORM -----------*/
      case "skills-form":
        tools.switchFrom(
          "skills-form",
          "formations",
          "",
          "form-h-skills-form",
          "",
          "form-barre-formations"
        );
        $("#form-h-description").removeClass("done");
        $("#form-barre-description").removeClass("done");
        this.setState({
          active_card: "formations",
          name_ac: "Historique",
        });
        break;

      /*------------ SUCCESSOR FORM -----------*/
      case "project-form":
        tools.switchFrom(
          "project-form",
          "skills-form",
          "",
          "form-h-skills-form",
          "",
          "form-barre-skills-form"
        );
        this.setState({
          active_card: "skills-form",
        });
        break;

      /*------------ VALIDATION FORM -----------*/
      case "successor-form":
        tools.switchFrom(
          "successor-form",
          "project-form",
          "",
          "form-h-project-form",
          "",
          "form-barre-project-form"
        );
        this.setState({
          active_card: "project-form",
          name_ac: "Description",
        });
        break;

      case "validation-form":
        tools.switchFrom(
          "validation-form",
          "successor-form",
          "",
          "form-h-successor-form",
          "",
          "form-barre-successor-form"
        );
        $("#next-form-talent").show();
        $("#submit-form-talent").hide();
        this.setState({
          active_card: "successor-form",
          name_ac: "Description",
        });
        break;

      /*------------ PERSONAL INFORMATION -----------*/

      default:
        break;
    }
  };

  deleteIteration = (e, array) => {
    e.preventDefault();
    const id_to_delete = $(e.target).attr("id");
    let total_course = array;
    for (const i in total_course) {
      if (total_course[i].id == id_to_delete) {
        total_course.splice(i, 1);
      }
    }
    this.setState({
      array: total_course,
    });
  };

  openForm = (e, id) => {
    e.preventDefault();
    $("#" + id).slideDown(function () {
      $("#" + id).css("display", "flex");
    });
  };

  closeForm = (e, id) => {
    e.preventDefault();
    $("#" + id).slideUp();
  };

  addProfCourse = (e) => {
    e.preventDefault();
    let total_course = this.state.professional_course;
    const course = {};
    course.date_start = this.state.date_poste_start;
    course.date_end = this.state.date_poste_end;
    course.title = this.state.title_poste;
    course.description = this.state.description_poste;
    course.id = Date.now();
    if (course.date_start === "0000-00-00") {
      $("#date_poste_start").addClass("error-input");
      return;
    } else {
      $("#date_poste_start").removeClass("error-input");
    }
    if (course.date_end === "0000-00-00") {
      $("#date_poste_end").addClass("error-input");
      return;
    } else {
      $("#date_poste_end").removeClass("error-input");
    }
    if (!course.title) {
      $("#title_poste").addClass("error-input");
      return;
    } else {
      $("#title_poste").removeClass("error-input");
    }
    if (!course.description) {
      $("#description_poste").addClass("error-input");
      return;
    } else {
      $("#description_poste").removeClass("error-input");
    }
    total_course.push(course);
    $("#parcoursPro").fadeOut();
    setTimeout(() => {
      this.setState({
        professional_course: total_course,
        date_poste_start: "0000-00-00",
        date_poste_end: "0000-00-00",
        title_poste: "",
        description_poste: "",
      });
      $("#date_poste_start").val("");
      $("#date_poste_end").val("");
      $("#title_poste").val("");
      $("#description_poste").val("");
      $("#cgucheck").prop("checked", false);
      $("#date_poste_end").removeAttr("disabled", "disabled");
    }, 400);
  };

  addFormation = (e) => {
    e.preventDefault();
    let total_formations = this.state.formations;
    const formation = {};
    formation.name = this.state.formation_name;
    formation.date = this.state.formation_year;
    formation.id = Date.now();
    if (!formation.name) {
      $("#formation_name").addClass("error-input");
      return;
    } else {
      $("#formation_name").removeClass("error-input");
    }
    if (!formation.date) {
      $("#formation_year").addClass("error-input");
      return;
    } else {
      $("#formation_year").removeClass("error-input");
    }
    total_formations.push(formation);
    $("#formationContainer").fadeOut();
    setTimeout(() => {
      this.setState({
        formations: total_formations,
        formation_name: "",
        formation_year: moment().format("YYYY"),
      });
      $("#formation_name").val("");
      $("#formation_year").val(moment().format("YYYY"));
    }, 400);
  };

  addStudies = (e) => {
    e.preventDefault();
    let total_studies = this.state.studies;
    const studies = {};
    studies.school_name = this.state.school_name;
    studies.school_formation_name = this.state.school_formation_name;
    studies.date = this.state.school_date;
    studies.id = Date.now();
    if (!studies.date) {
      $("#school_date").addClass("error-input");
      return;
    } else {
      $("#school_date").removeClass("error-input");
    }
    if (!studies.school_formation_name) {
      $("#school_formation_name").addClass("error-input");
      return;
    } else {
      $("#school_formation_name").removeClass("error-input");
    }
    if (!studies.school_name) {
      $("#school_name").addClass("error-input");
      return;
    } else {
      $("#school_name").removeClass("error-input");
    }
    $("#schoolContainer").fadeOut();
    total_studies.push(studies);

    setTimeout(() => {
      this.setState({
        studies: total_studies,
        school_name: "",
        school_formation_name: "",
        school_date: moment().format("YYYY"),
      });
      $("#school_name").val("");
      $("#school_formation_name").val("");
      $("#school_date").val(moment().format("YYYY"));
    }, 400);
  };

  addLanguage = (e) => {
    e.preventDefault();
    let total_language = this.state.language_total;
    const language = {};
    language.code = this.state.language;
    language.id = Date.now();
    if (!language.code) {
      $("#select_language").addClass("error-input");
      return;
    } else {
      $("#select_language").removeClass("error-input");
    }
    $("#languageContainer").fadeOut();
    total_language.push(language);
    setTimeout(() => {
      this.setState({
        language_total: total_language,
        language: "",
      });
    }, 400);
  };

  addHardskill = (e) => {
    e.preventDefault();
    let check = false;
    let total_skill = this.state.total_hard_skills;
    for (const i in total_skill) {
      if (total_skill[i].name === this.state.search_hardskill) check = true;
    }
    if (!check) {
      const skill = {};
      skill.name = this.state.search_hardskill;
      skill.level = this.state.skill_level;
      skill.id = Date.now();
      if (!skill.name) {
        $("#search_hardskill").addClass("error-input");
        return;
      } else {
        $("#search_hardskill").removeClass("error-input");
      }
      total_skill.push(skill);
      $("#hardSkillContainer").fadeOut();
      setTimeout(() => {
        this.setState({
          total_hard_skills: total_skill,
          search_hardskill: "",
          skill_level: 3,
        });
        $("#search_hardskill").val("");
        $("#selectLevelBarre0+label").addClass("active-radio");
        $("#selectLevelBarre1+label").addClass("active-radio");
        $("#selectLevelBarre2+label").addClass("active-radio");
        $("#selectLevelBarre3+label").removeClass("active-radio");
        $("#selectLevelBarre4+label").removeClass("active-radio");
      }, 400);
    }
  };

  addSoftskill = (e) => {
    e.preventDefault();
    let total_skill = this.state.total_soft_skills;
    const skill = {};
    skill.name = this.state.search_softskill;
    skill.id = Date.now();
    if (!skill.name) {
      $("#search_softskill").addClass("error-input");
      return;
    } else {
      $("#search_softskill").removeClass("error-input");
    }
    total_skill.push(skill);
    $("#softskillsContaier").fadeOut();
    setTimeout(() => {
      this.setState({
        total_soft_skills: total_skill,
        search_softskill: "",
      });
      $("#search_softskill").val("");
    }, 400);
  };

  addProject = (e) => {
    e.preventDefault();
    let total_project = this.state.projects;
    const project = {};
    project.description = this.state.add_project;
    project.id = Date.now();
    if (!project.description) {
      $("#add_project").addClass("error-input");
      return;
    } else {
      $("#add_project").removeClass("error-input");
    }
    total_project.push(project);
    $("#projectContainer").fadeOut();
    setTimeout(() => {
      this.setState({
        projects: total_project,
        add_project: "",
      });
      $("#add_project").val("");
    }, 400);
  };

  addNote = (e) => {
    e.preventDefault();
    let total_project = this.state.notes;
    const project = {};
    project.description = this.state.add_note;
    project.id = Date.now();
    if (!project.description) {
      $("#add_note").addClass("error-input");
      return;
    } else {
      $("#add_note").removeClass("error-input");
    }
    total_project.push(project);
    $("#noteContainer").fadeOut();
    setTimeout(() => {
      this.setState({
        notes: total_project,
        add_note: "",
      });
      $("#add_note").val("");
    }, 400);
  };

  addSucceed = (e) => {
    e.preventDefault();
    let total_person = this.state.search_person_total;
    const person = {};
    if (this.state.search_person.length === 0) {
      $("#search_person").addClass("error-input");
      return;
    } else {
      $("#search_person").removeClass("error-input");
    }
    const id = tools.getIDDataList(this.state.search_person);
    const name = tools.getNameDataList(this.state.search_person);
    person.name = name;
    person.talent_id = id;
    person.id = Date.now();

    total_person.push(person);
    $("#succeedContainer").fadeOut();
    setTimeout(() => {
      this.setState({
        search_person_total: total_person,
        search_person: "",
      });
      $("#search_person").val("");
    }, 400);
  };

  addMaySucceed = (e) => {
    e.preventDefault();
    let total_person = this.state.search_person_2_total;
    const person = {};
    if (this.state.search_person_2.length === 0) {
      $("#search_person_2").addClass("error-input");
      return;
    } else {
      $("#search_person_2").removeClass("error-input");
    }
    const id = tools.getIDDataList(this.state.search_person_2);
    const name = tools.getNameDataList(this.state.search_person_2);
    person.name = name;
    person.talent_id = id;
    person.id = Date.now();
    total_person.push(person);
    $("#maySucceedContainer").fadeOut();
    setTimeout(() => {
      this.setState({
        search_person_2_total: total_person,
        search_person_2: "",
      });
      $("#search_person_2").val("");
    }, 400);
  };

  sortSkills = (array, state_array) => {
    let name_list = "";
    let skills_sorted = [];
    if (state_array.length > 0) {
      for (let i = 0; i < state_array.length; i++) {
        name_list = name_list + "|" + state_array[i].name;
      }
    }

    if (array.length > 0) {
      for (let j = 0; j < array.length; j++) {
        if (!name_list.includes(array[j].name)) skills_sorted.push(array[j]);
      }
    } else skills_sorted = array;
    return skills_sorted;
  };

  submitForm = (e) => {
    /*---------------------------------------- CHECKER LES STATE VAR POUR GESTIONS DES ERR  */
    e.preventDefault();
    let hardSkills = [];
    let softSkills = [];
    if (this.state.total_hard_skills.length > 0 && this.state.hard_skills)
      hardSkills = this.sortSkills(
        this.state.total_hard_skills,
        this.state.hard_skills
      );
    else if (this.state.total_hard_skills.length > 0)
      hardSkills = this.state.total_hard_skills;

    if (this.state.total_soft_skills.length > 0 && this.state.soft_skills)
      softSkills = this.sortSkills(
        this.state.total_soft_skills,
        this.state.soft_skills
      );
    else if (this.state.total_soft_skills.length > 0)
      softSkills = this.state.total_hard_skills;

    if (hardSkills.length > 0 || softSkills.length > 0) {
      const formSkills = new FormData();
      if (hardSkills.length > 0)
        formSkills.append("hardskills", JSON.stringify(hardSkills));
      else formSkills.append("hardskills", null);
      if (softSkills.length > 0)
        formSkills.append("softskills", JSON.stringify(softSkills));
      else formSkills.append("softskills", null);
      axios
        .post(process.env.REACT_APP_API + "/logged/add-skill", formSkills, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => error);
    }
    console.log(this.state.file);
    const formData = new FormData();
    console.log(this.state.talent.id);
    formData.append("id", this.state.talent.id);
    formData.append("url_pic", this.state.talent.url_pic);
    formData.append("file", this.state.file);
    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);
    formData.append("email", this.state.mail);
    formData.append("address", this.state.address);
    formData.append("phone", this.state.phone);
    formData.append("gender", this.state.gender);
    formData.append("language", JSON.stringify(this.state.language_total));
    formData.append("birthday", this.state.birthday);
    formData.append("nine_box", this.state.ninebox);
    formData.append("job", this.state.add_poste);
    formData.append("id_entity", this.state.select_entity_id);
    formData.append("service", this.state.service);
    formData.append("hierarchy", this.state.search_hierarchy);
    formData.append("hard_skill", JSON.stringify(this.state.total_hard_skills));
    formData.append("soft_skill", JSON.stringify(this.state.total_soft_skills));
    formData.append(
      "may_succeed",
      JSON.stringify(this.state.search_person_2_total)
    );
    formData.append("succeed", JSON.stringify(this.state.search_person_total));
    formData.append("course", JSON.stringify(this.state.professional_course));
    formData.append("studies", JSON.stringify(this.state.studies));
    formData.append("formations", JSON.stringify(this.state.formations));
    formData.append("projects", JSON.stringify(this.state.projects));
    formData.append("notes", JSON.stringify(this.state.notes));
    formData.append("label", this.state.label_talent);

    if (
      this.state.file !== "" ||
      this.state.first_name !== "" ||
      this.state.last_name !== ""
    ) {
      axios
        .put(process.env.REACT_APP_API + "/logged/update-talent", formData, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response);
          window.location.href = "/";
        })
        .catch((error) => error);
    } else
      alert("Veuillez remplir les champs nécéssaires (nom, prénom, photo)");
  };

  Checked = () => {
    this.state.gender === "man"
      ? $("#man").attr("checked", "checked")
      : this.state.gender === "woman"
      ? $("#woman").attr("checked", "checked")
      : $("#other").attr("checked", "checked");
  };

  render() {
    //console.log(this.state.talent);
    return (
      <section id="talent-add-container" className="container">
        <div className="breadcrumb-block">
          <div className="breadcrumb">
            <h1>
              <i className="icon">&#xe912;</i>
              création fiche talent
            </h1>
            <h2 id="form-h-personnal-informations" className="in-progress">
              information <br /> personnelle
            </h2>
            <div
              id="form-barre-personnal-informations"
              className="barre-progress"
            ></div>

            <h2 id="form-h-historique" className="">
              Historique
            </h2>
            <div className="d-flex" id="historique-form-subsection">
              <div id="form-barre-historique" className="barre-progress"></div>
              <div>
                <h3 id="form-h-course" className="">
                  parcours
                </h3>
                <h3 id="form-h-formations" className="">
                  formation / étude
                </h3>
              </div>
            </div>

            <h2 id="form-h-description" className="">
              description
            </h2>
            <div className="d-flex" id="description-form-subsection">
              <div id="form-barre-description" className="barre-progress"></div>
              <div>
                <h3 id="form-h-skills-form" className="">
                  compétence
                </h3>
                <h3 id="form-h-project-form" className="">
                  projet & attente
                </h3>
                <h3 id="form-h-successor-form" className="">
                  poste & sucession
                </h3>
              </div>
            </div>

            <h2 id="form-h-validation">validation</h2>
            <br />
            <p className="t-center">
              <a href="/" className="primary-action-btn">
                Quitter
              </a>
            </p>
          </div>
        </div>
        <div className="talent-form-container">
          <h2>{this.state.name_ac}</h2>
          <form action="">
            {/* --------- INFORMATION PERSONNELLE --------- */}
            <div className="form-section" id="personnal-informations">
              <div className="left-section">
                <div className="two-col">
                  <label htmlFor="first-name">Prénom*</label>
                  <input
                    name="first_name"
                    onChange={this.onChange}
                    type="text"
                    id="first_name"
                    placeholder="JEAN"
                    value={this.state.first_name}
                  />
                </div>
                <div className="two-col">
                  <label htmlFor="last-name">Nom*</label>
                  <input
                    name="last_name"
                    onChange={this.onChange}
                    type="text"
                    id="last_name"
                    placeholder="VALGENS"
                    value={this.state.last_name}
                  />
                </div>
                <div className="one-col input-radio">
                  <p>
                    {this.Checked()}
                    <input
                      onClick={this.onChangeGenre}
                      type="radio"
                      id="man"
                      name="genre"
                      value="man"
                    />
                    <label for="man">Homme</label>
                  </p>
                  <p>
                    <input
                      onClick={this.onChangeGenre}
                      type="radio"
                      id="woman"
                      name="genre"
                      value="woman"
                    />
                    <label for="woman">Femme</label>
                  </p>
                  <p className="d-none">
                    <input
                      onClick={this.onChangeGenre}
                      type="radio"
                      id="other"
                      name="genre"
                      value="other"
                    />
                    <label for="other">Pesonnalisé</label>
                  </p>
                </div>
                <div className="two-col">
                  <label htmlFor="birthday">Date de naissance</label>
                  <input
                    name="birthday"
                    onChange={this.onChange}
                    type="date"
                    id="birthday"
                    value={this.state.birthday}
                  />
                </div>
                <div className="two-col">
                  <label htmlFor="phone">Numéro de téléphone</label>
                  <input
                    name="phone"
                    onChange={this.onChange}
                    type="tel"
                    id="phone"
                    placeholder="08 88 88 88 88"
                    value={this.state.phone}
                  />
                </div>
                <div className="one-col">
                  <label htmlFor="mail">Adresse E-mail</label>
                  <input
                    onChange={this.onChange}
                    name="mail"
                    type="email"
                    id="mail"
                    placeholder="jean.valgens@entreprise.com"
                    value={this.state.mail}
                  />
                </div>
                <div className="one-col">
                  <label htmlFor="adress">Adresse</label>
                  <input
                    onChange={this.onChange}
                    name="address"
                    type="text"
                    id="address"
                    placeholder="4 Rue de l'entreprise, 88888, Ville"
                    value={this.state.address}
                  />
                </div>
              </div>
              <div className="right-section">
                <div class="talent-container">
                  <div>
                    <img
                      id="picture-of-talent-preview"
                      src={
                        process.env.REACT_APP_API +
                        "/storage/" +
                        this.state.talent.url_pic
                      }
                      alt="talent name"
                      className="picture-of-talent"
                    />
                  </div>
                </div>
                <h3 id="fileNameForm">{this.state.fileName}</h3>
                <div className="upload-btn-talent">
                  <p>Ajouter une photo</p>
                  <input
                    onChange={this.onChangeFile}
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
            </div>

            {/* --------- PARCOURS --------- */}
            <div className="form-section form-off" id="course">
              <h4>Parcours</h4>
              <div className="row-section">
                <ul className="row-section render-items-form">
                  {this.state.professional_course.map((step) => (
                    <li>
                      <div>
                        <h2>
                          {moment(step.date_start).format("DD/MM/YYYY")}
                          <i className="icon">&#xe907;</i>
                          {moment(step.date_end).format("DD/MM/YYYY")} -
                          <b> {step.title}</b>
                        </h2>
                        <p>{step.description}</p>
                      </div>
                      <div>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <i className="icon">&#xe912;</i>
                        </a>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(
                                e,
                                this.state.professional_course
                              )
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="form-addTalent-container" id="parcoursPro">
                  <div className="two-col course-form-date">
                    <p className="label-detached">Date</p>
                    <input
                      name="date_poste_start"
                      onChange={this.onChange}
                      type="date"
                      id="date_poste_start"
                    />
                    <span>au</span>
                    <input
                      name="date_poste_end"
                      onChange={this.onChange}
                      type="date"
                      id="date_poste_end"
                      value={this.state.date_poste_end}
                    />
                  </div>
                  <div className="two-col">
                    <label htmlFor="title_poste">Intitulé du poste</label>
                    <br />
                    <br />
                    <input
                      onChange={this.onChange}
                      name="title_poste"
                      type="text"
                      id="title_poste"
                      placeholder="Directeur de gestion"
                    />
                  </div>
                  <div className="one-col">
                    <input
                      class="input-checkbox-login"
                      type="checkbox"
                      id="cgucheck"
                      required=""
                      value=""
                      onClick={() => {
                        if (this.state.cgucheck) {
                          $("#date_poste_end").removeAttr("disabled");
                          this.setState({
                            date_poste_end: "",
                            cgucheck: false,
                          });
                        } else {
                          let dateObj = new Date();
                          let month = dateObj.getUTCMonth() + 1; //months from 1-12
                          if (month < 10) {
                            month = "0" + month.toString();
                          }
                          let day = dateObj.getUTCDate();
                          if (day < 10) {
                            day = "0" + day.toString();
                          }
                          let year = dateObj.getUTCFullYear();
                          $("#date_poste_end").attr("disabled", "disabled");
                          this.setState({
                            date_poste_end: year + "-" + month + "-" + day,
                            cgucheck: true,
                          });
                        }
                      }}
                    />
                    <label class="form-check-label" for="cgucheck">
                      Poste actuel
                    </label>
                  </div>
                  <div className="one-col">
                    <label htmlFor="description_poste">Description</label>
                    <br />
                    <br />
                    <textarea
                      onChange={this.onChange}
                      name="description_poste"
                      id="description_poste"
                      placeholder="Stage pratique dans ..."
                    ></textarea>
                    <br />
                    <br />
                    <div className="validation-btn-container">
                      <button
                        onClick={(e) => this.closeForm(e, "parcoursPro")}
                        className="primary-action-btn"
                      >
                        Annuler
                      </button>
                      <button
                        onClick={this.addProfCourse}
                        className="primary-action-btn"
                      >
                        Valider
                      </button>
                    </div>
                  </div>
                </div>
                <br />

                <button
                  onClick={(e) => this.openForm(e, "parcoursPro")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter un parcours pro
                </button>
              </div>
              <br />
              <br />
              <div className="hr-custom" />
            </div>

            {/* --------- FORMATIONS --------- */}
            <div className="form-section form-off" id="formations">
              <div className="row-section">
                <h4>Formations</h4>
                <ul className="row-section render-items-form">
                  {this.state.formations.map((step) => (
                    <li>
                      <div>
                        <h2>
                          {moment(step.date).format("YYYY")} -
                          <b> {step.name}</b>
                        </h2>
                      </div>
                      <div>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <i className="icon">&#xe912;</i>
                        </a>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(e, this.state.formations)
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>

                <div
                  className="form-addTalent-container"
                  id="formationContainer"
                >
                  <div className="one-col formation-add-form">
                    <p>
                      <label htmlFor="formation-name">
                        Intitulé de la formation
                      </label>
                      <br />
                      <input
                        onChange={this.onChange}
                        name="formation_name"
                        type="text"
                        id="formation_name"
                        placeholder="DDA Experties"
                      />
                    </p>
                    <p>
                      <label htmlFor="formation-year">Année</label>

                      <select
                        name="formation_year"
                        id="formation_year"
                        onChange={this.onChange}
                        value={this.state.value}
                      >
                        <option
                          value={moment().format("YYYY")}
                          selected
                          disabled
                          hidden
                        >
                          {moment().format("YYYY")}
                        </option>
                        {this.state.year.map((year) => (
                          <option value={year.year}>{year.year}</option>
                        ))}
                      </select>
                    </p>
                  </div>
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "formationContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addFormation}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <button
                  onClick={(e) => this.openForm(e, "formationContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter une formation
                </button>
                <br />
                <br />
                <br />
                <h4>Diplômes et qualifications</h4>
                <ul className="row-section render-items-form">
                  {this.state.studies.map((step) => (
                    <li>
                      <div>
                        <h2>
                          {step.date} -<b> {step.school_name}</b>
                        </h2>
                        <p>{step.school_formation_name}</p>
                      </div>
                      <div>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <i className="icon">&#xe912;</i>
                        </a>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(e, this.state.studies)
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <br />
                <div className="form-addTalent-container" id="schoolContainer">
                  <div className="two-col">
                    <p>
                      <label htmlFor="school_date">Année</label>

                      <select
                        name="school_date"
                        id="school_date"
                        onChange={this.onChange}
                        value={this.state.value}
                      >
                        <option
                          value={moment().format("YYYY")}
                          selected
                          disabled
                          hidden
                        >
                          {moment().format("YYYY")}
                        </option>
                        {this.state.year.map((year) => (
                          <option value={year.year}>{year.year}</option>
                        ))}
                      </select>
                    </p>
                  </div>
                  <div className="two-col">
                    <p>
                      <label htmlFor="school_formation_name">
                        Niveau de qualification ou de scolarité
                      </label>

                      <select
                        name="school_formation_name"
                        id="school_formation_name"
                        onChange={this.onChange}
                        value={this.state.value}
                      >
                        <option value="">Choisissez un niveau</option>
                        <option value="Bac +5 et plus (Certification de niveau 1)">
                          Bac +5 et plus (Certification de niveau 1)
                        </option>
                        <option value="Bac +3 et +4 (Certification de niveau 2)">
                          Bac +3 et +4 (Certification de niveau 2)
                        </option>
                        <option value="Bac +2 (Certification de niveau 3)">
                          Bac +2 (Certification de niveau 3)
                        </option>
                        <option value="Bac (Certification de niveau 4)">
                          Bac (Certification de niveau 4)
                        </option>
                        <option value="CAP, BEP (Certification de niveau 5)">
                          CAP, BEP (Certification de niveau 5)
                        </option>
                        <option value="Seconde ou première achevée">
                          Seconde ou première achevée
                        </option>
                        <option value="CFG ou CEP">CFG ou CEP</option>
                        <option value="BEPC / 3ème achevée">
                          BEPC / 3ème achevée
                        </option>
                        <option value="Primaire à 4ème achevée">
                          Primaire à 4ème achevée
                        </option>
                        <option value="Aucune formation scolaire">
                          Aucune formation scolaire
                        </option>
                      </select>
                    </p>
                  </div>
                  <div className="one-col">
                    <label htmlFor="school-name">
                      Intitulé du diplôme ou de la qualification
                    </label>
                    <input
                      onChange={this.onChange}
                      name="school_name"
                      type="text"
                      id="school_name"
                      placeholder="Ecole"
                    />
                  </div>
                  <br />
                  <br />
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "schoolContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addStudies}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <button
                  onClick={(e) => this.openForm(e, "schoolContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter un diplôme
                </button>
              </div>
              <br />
              <br />
              <div className="hr-custom" />
            </div>

            {/* --------- COMPETENCES --------- */}
            <div className="form-section form-off" id="skills-form">
              <div className="row-section">
                <h4>Hard Skills</h4>
                <ul className="row-section render-items-form">
                  {this.state.total_hard_skills.map((step) => (
                    <li>
                      <div>
                        <h2>{step.name}</h2>
                        <div className="skill-bar">
                          <div skill={step.level}></div>
                        </div>
                      </div>
                      <div>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <i className="icon">&#xe912;</i>
                        </a>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(
                                e,
                                this.state.total_hard_skills
                              )
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  className="form-addTalent-container"
                  id="hardSkillContainer"
                >
                  <div className="two-col">
                    <label htmlFor="search-hardskill">Compétence</label>
                    <input
                      onChange={this.onChange}
                      placeholder="PowerPoint, Suite Office, Photoshop..."
                      type="text"
                      list="hardskill"
                      name="search_hardskill"
                      id="search_hardskill"
                    />
                    <datalist id="hardskill">
                      {this.state.hard_skills.map((step) => (
                        <option value={step.name}>{step.name}</option>
                      ))}
                    </datalist>
                    <br />
                  </div>
                  <div className="two-col">
                    <p className="label-detached t-center">Niveau</p>
                    <div className="select-level-barre jc-space-between ">
                      <div className="radio-custom">
                        <input
                          type="radio"
                          id="selectLevelBarre0"
                          name="selectLevelBarre"
                          value="1"
                          checked
                        />
                        <label for="selectLevelBarre0"></label>
                      </div>
                      <div className="barre-spacer"></div>
                      <div className="radio-custom">
                        <input
                          type="radio"
                          id="selectLevelBarre1"
                          name="selectLevelBarre"
                          value="2"
                        />
                        <label for="selectLevelBarre1"></label>
                      </div>
                      <div className="barre-spacer"></div>

                      <div className="radio-custom">
                        <input
                          type="radio"
                          id="selectLevelBarre2"
                          name="selectLevelBarre"
                          value="3"
                        />
                        <label for="selectLevelBarre2"></label>
                      </div>
                      <div className="barre-spacer"></div>

                      <div className="radio-custom">
                        <input
                          type="radio"
                          id="selectLevelBarre3"
                          name="selectLevelBarre"
                          value="4"
                        />
                        <label for="selectLevelBarre3"></label>
                      </div>
                      <div className="barre-spacer"></div>

                      <div className="radio-custom">
                        <input
                          type="radio"
                          id="selectLevelBarre4"
                          name="selectLevelBarre"
                          value="5"
                        />
                        <label for="selectLevelBarre4"></label>
                      </div>
                    </div>
                    <div className="d-flex jc-space-between label-level-barre">
                      <span>Débutant</span>
                      <span>Intermédiaire</span>
                      <span>Expert</span>
                    </div>
                  </div>
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "hardSkillContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addHardskill}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <button
                  onClick={(e) => this.openForm(e, "hardSkillContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter un hard skill
                </button>
                <br />
                <br />
                <h4>Langage</h4>
                <ul className="row-section render-items-form language-list-selected">
                  {this.state.language_total.map((step) => (
                    <li>
                      <div>
                        <img
                          src={
                            process.env.REACT_APP_API +
                            "/flag/" +
                            step.code +
                            ".svg"
                          }
                          alt="flag"
                        />
                        <p>
                          {
                            countryJSON[
                              countryJSON.findIndex((x) => x.code === step.code)
                            ].langue
                          }
                        </p>
                      </div>
                      <div>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(e, this.state.language_total)
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  className="form-addTalent-container"
                  id="languageContainer"
                >
                  <div className="one-col">
                    <label htmlFor="select_language">
                      Rechercher une langue
                    </label>
                    <select
                      name="language"
                      id="select_language"
                      onChange={this.onChange}
                      value={this.state.value}
                    >
                      <optgroup label="Langues fréquemment utilisées">
                        <option value="">Choisissez une langue</option>
                        <option value="AN">Anglais</option>
                        <option value="FR">Français</option>
                        <option value="ES">Espagnol</option>
                        <option value="AL">Allemand</option>
                        <option value="AR">Arabe</option>
                        <option value="IT">Italien</option>
                      </optgroup>
                      <optgroup label="Toutes les langues">
                        {countryJSON.map((country) => (
                          <option value={country.code}>{country.langue}</option>
                        ))}
                      </optgroup>
                    </select>
                    <br />
                  </div>
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "languageContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addLanguage}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <button
                  onClick={(e) => this.openForm(e, "languageContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter une langue
                </button>
                <br />
                <br />
                <h4>Softskills</h4>
                <ul className="row-section render-items-form softskill-list-selected">
                  {this.state.total_soft_skills.map((step) => (
                    <li>
                      <div>
                        <p>{step.name}</p>
                      </div>

                      <div>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(
                                e,
                                this.state.total_soft_skills
                              )
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  className="form-addTalent-container"
                  id="softskillsContaier"
                >
                  <div className="one-col">
                    <label htmlFor="search-softskill">
                      Rechercher un softskill
                    </label>
                    <input
                      onChange={this.onChange}
                      name="search_softskill"
                      placeholder="Patient, organisé, curieux..."
                      type="text"
                      list="softskill"
                      id="search_softskill"
                    />
                    <datalist id="softskill">
                      {this.state.soft_skills.map((step) => (
                        <option value={step.name}>{step.name}</option>
                      ))}
                    </datalist>
                    <br />
                    <div className="validation-btn-container">
                      <button
                        onClick={(e) => this.closeForm(e, "softskillsContaier")}
                        className="primary-action-btn"
                      >
                        Annuler
                      </button>
                      <button
                        onClick={this.addSoftskill}
                        className="primary-action-btn"
                      >
                        Valider
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <button
                onClick={(e) => this.openForm(e, "softskillsContaier")}
                className="add-form-btn"
              >
                <i className="icon">&#xe91a;</i> Ajouter un soft skill
              </button>
              <br />
              <br />
              <div className="hr-custom" />
            </div>

            {/* --------- PROJETS ATTENTES --------- */}
            <div className="form-section form-off" id="project-form">
              <div className="row-section">
                <h4>Projets & Attentes</h4>
                <ul className="row-section render-items-form">
                  {this.state.projects.map((step) => (
                    <li>
                      <div>
                        <h2>{step.description}</h2>
                      </div>
                      <div>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <i className="icon">&#xe912;</i>
                        </a>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(e, this.state.projects)
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="form-addTalent-container" id="projectContainer">
                  <div className="one-col">
                    <label htmlFor="add_project">Description</label>
                    <br />
                    <br />
                    <textarea
                      onChange={this.onChange}
                      name="add_project"
                      id="add_project"
                      placeholder="Souhaite partir à l’international..."
                    ></textarea>
                    <br />
                    <br />
                  </div>
                  <br />
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "projectContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addProject}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <button
                  onClick={(e) => this.openForm(e, "projectContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter un projet skill
                </button>
                <br />
                <br />
                <h4>Notes</h4>
                <ul className="row-section render-items-form">
                  {this.state.notes.map((step) => (
                    <li>
                      <div>
                        <h2>{step.description}</h2>
                      </div>
                      <div>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                          <i className="icon">&#xe912;</i>
                        </a>
                        <a href="/">
                          <i
                            id={step.id}
                            onClick={(e) =>
                              this.deleteIteration(e, this.state.notes)
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="form-addTalent-container" id="noteContainer">
                  <div className="one-col">
                    <label htmlFor="add-note">Ajouter une note</label>
                    <br />
                    <br />
                    <textarea
                      onChange={this.onChange}
                      name="add_note"
                      id="add_note"
                      placeholder="Souhaite partir à l’international..."
                    ></textarea>
                  </div>
                  <br />
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "noteContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addNote}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <button
                  onClick={(e) => this.openForm(e, "noteContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter une note
                </button>
                <br />
                <div className="hr-custom" />
              </div>
            </div>

            {/* --------- SUCESSION --------- */}
            <div className="form-section form-off" id="successor-form">
              <div className="row-section">
                <h4>Poste actuel</h4>
                <br />
                <div className="form-addTalent-container" id="actualPoste">
                  <div className="one-col">
                    <label htmlFor="add-poste">Ajouter le poste</label>
                    <input
                      onChange={this.onChange}
                      name="add_poste"
                      type="text"
                      placeholder="Directeur financier"
                      id="add_poste"
                      value={this.state.talent.job}
                    />
                  </div>
                  <div className="one-col">
                    <label htmlFor="service">Ajouter le service</label>
                    <select
                      onChange={this.onChange}
                      value={this.state.talent.service}
                      name="service"
                      id="service"
                    >
                      <option value="">Choisissez un service</option>

                      <option value="Achats">Achats</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Communication">Communication</option>
                      <option value="Logistique">Logistique</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Finance">Finance</option>
                      <option value="Industriel">Industriel</option>
                      <option value="Direction Technique">
                        Direction Technique
                      </option>
                      <option value="Ressources Humaines">
                        Ressources Humaines
                      </option>
                      <option value="Juridique">Juridique</option>
                      <option value="Direction Générale">
                        Direction Générale
                      </option>
                      <option value="Gestion / Comptabilité">
                        Gestion / Comptabilité
                      </option>
                      <option value="Santé/Sécurité">Santé/Sécurité</option>
                      <option value="Innovation">Innovation</option>
                      <option value="Production">Production</option>
                    </select>
                  </div>
                  <div className="two-col">
                    <label htmlFor="search-person">
                      Ajouter le niveau hiérarchique
                    </label>
                    <select
                      onChange={this.onChange}
                      value={this.state.talent.search_hierarchy}
                      name="search_hierarchy"
                      id="search_hierarchy"
                    >
                      <option value="Employer">Employer</option>
                      <option value="Agent de métrise">Agent de métrise</option>
                      <option value="Cadre">Cadre</option>
                      <option value="Cadre supérieur">Cadre supérieur</option>
                      <option value="Directeur">Directeur</option>
                    </select>
                  </div>
                  <div className="two-col">
                    <label htmlFor="search_entity">Entité</label>
                    <select
                      name="select_entity_id"
                      id="search_entity"
                      onChange={this.onChange}
                      value={this.state.talent.service}
                    >
                      {this.state.entities.map((entity) => (
                        <option value={entity.id}>{entity.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <br />
                <h4>Peut succéder à</h4>
                <ul className="row-section render-items-form successor-selected">
                  {this.state.search_person_total.map((person) => (
                    <li>
                      <div>
                        <h2>{person.name}</h2>
                      </div>
                      <div>
                        <a href="/">
                          <i
                            id={person.id}
                            onClick={(e) =>
                              this.deleteIteration(
                                e,
                                this.state.search_person_total
                              )
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="form-addTalent-container" id="succeedContainer">
                  <div className="one-col">
                    <label htmlFor="search-person">Ajouter une personne</label>
                    <select
                      onChange={this.onChange}
                      value={this.state.value}
                      name="search_person"
                      id="search_person"
                    >
                      <option value="">Choisissez une personne</option>
                      {this.state.talents.map((talent) => (
                        <option
                          value={
                            "#" +
                            talent.id +
                            " - " +
                            talent.first_name +
                            " " +
                            talent.last_name
                          }
                        >
                          #{talent.id} - {talent.first_name} {talent.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "succeedContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addSucceed}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <button
                  onClick={(e) => this.openForm(e, "succeedContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i> Ajouter une personne à
                  succéder
                </button>

                <br />
                <br />
                <h4>A pour successeurs potentiels</h4>
                <ul className="row-section render-items-form successor-selected">
                  {this.state.search_person_2_total.map((person) => (
                    <li>
                      <div>
                        <h2>{person.name}</h2>
                      </div>
                      <div>
                        <a href="/">
                          <i
                            id={person.id}
                            onClick={(e) =>
                              this.deleteIteration(
                                e,
                                this.state.search_person_2_total
                              )
                            }
                            className="icon"
                          >
                            &#xe90e;
                          </i>
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  className="form-addTalent-container"
                  id="maySucceedContainer"
                >
                  <div className="one-col">
                    <label htmlFor="search_person_2">
                      Ajouter une personne
                    </label>

                    <select
                      onChange={this.onChange}
                      value={this.state.value}
                      name="search_person_2"
                      id="search_person_2"
                    >
                      <option value="">Choisissez une personne</option>
                      {this.state.talents.map((talent) => (
                        <option
                          value={
                            "#" +
                            talent.id +
                            " - " +
                            talent.first_name +
                            " " +
                            talent.last_name
                          }
                        >
                          #{talent.id} - {talent.first_name} {talent.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />
                  <div className="validation-btn-container">
                    <button
                      onClick={(e) => this.closeForm(e, "maySucceedContainer")}
                      className="primary-action-btn"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={this.addMaySucceed}
                      className="primary-action-btn"
                    >
                      Valider
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <button
                  onClick={(e) => this.openForm(e, "maySucceedContainer")}
                  className="add-form-btn"
                >
                  <i className="icon">&#xe91a;</i>Ajouter un seuccésseur
                </button>
              </div>
            </div>

            {/* --------- VALIDATION --------- */}
            <div className="form-section form-off" id="validation-form">
              <div className="row-section">
                <div
                  className="form-addTalent-container"
                  id="validationContainer"
                >
                  <div className="one-col">
                    <label htmlFor="ninebox">Niveau talent</label>

                    <select
                      onChange={this.onChange}
                      value={this.state.talent.ninebox}
                      name="ninebox"
                      id="search_input_ninebox"
                    >
                      <option value="A1">A1</option>
                      <option value="A2">A2</option>
                      <option value="A3">A3</option>
                      <option value="B1">B1</option>
                      <option value="B2">B2</option>
                      <option value="B3">B3</option>
                      <option value="C1">C1</option>
                      <option value="C2">C2</option>
                      <option value="C3">C3</option>
                    </select>

                    <br />
                    <label htmlFor="search_label">Etiquette</label>
                    <select
                      name="label_talent"
                      id="search_label"
                      onChange={this.onChange}
                      value={this.state.label_talent}
                    >
                      <option value="0">Aucun</option>
                      <option value="1">Plus de responsabilités</option>
                      <option value="2">Plus de mobilité</option>
                      <option value="3">À suivre de près </option>
                    </select>
                    <p>Vous pouvez modifier la fiche à tout moment</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="next-page-form">
              <button id="precedent-form-talent" onClick={this.precedentCard}>
                Précedent
              </button>
              <button id="next-form-talent" onClick={this.changeCard}>
                Valider et continuer
              </button>
              <button onClick={this.submitForm} id="submit-form-talent">
                Valider
              </button>
            </div>
          </form>
        </div>
      </section>
    );
  }
}
