import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import Result from "./Result";
import Loader from "../Loader/Loader";
import ToolsBar from "../Admin/ToolsBar";
import Navbar from "../Navbar/Navbar";
import NotFoundTalents from "../Talent/NotFoundTalents/NotFoundTalents";
import editIcon from "../../img/pencil-edit-button.svg";
import "../../style/home/home.css";
import "../../style/home/search.css";
import "../../style/loader.css";
import mobility from "../../img/icons/more-mobility.svg";
import follow from "../../img/icons/follow.svg";
import responsability from "../../img/icons/more-responsability.svg";

const tools = require("../../fct-tools");
const token = tools.getToken();

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      talentsStatusCode: 200,
      talents: [""],
      entities: [""],
      softSkills: [""],
      isConnect: false,

      //filter sorting
      filter: null,

      //entity sorting
      search_by_entity: "Entités",
      select_entity_id: null,

      //nine box sorting
      search_by_nineBox: "Nine Box",

      //softskill
      search_by_skill: "Skill",

      //gender
      select_gender: "Homme/Femme",
      search_gender: null,

      //label
      search_by_label: "0",

      //total sort
      nb_sort_active: 0,
    };
  }

  onFilterChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  componentDidMount() {
    const talents_getter = axios.get(
      process.env.REACT_APP_API + "/logged/show-talents",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const entities_getter = axios.get(
      process.env.REACT_APP_API + "/logged/show-entities",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const skills_getter = axios.get(
      process.env.REACT_APP_API + "/logged/show_soft_skills",
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const username = axios.get(process.env.REACT_APP_API + "/is-logged", {
      headers: { Authorization: `Bearer ${token}` },
    });
    axios
      .all([talents_getter, entities_getter, skills_getter, username])
      .then((result) => {
        localStorage.setItem(
          "username",
          result[3].data.user.payload.first_name
        );
        this.setState({
          talents: result[0].data,
          talentsStatusCode: result[0].status,
          entities: result[1].data,
          softSkills: result[2].data,
          isConnect: true,
        });
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/login";
      });
  }

  cntFilter = () => {
    /*-------------ADD NEW FILTER HERE--------------*/
    let cnt = 0;
    if (this.state.select_entity_id > 0) {
      cnt = cnt + 1;
    }
    if (this.state.search_by_nineBox !== "Nine Box") {
      cnt = cnt + 1;
    }
    if (this.state.search_by_skill != "Skill") {
      cnt = cnt + 1;
    }
    if (this.state.select_gender != "Homme/Femme") {
      cnt = cnt + 1;
    }
    if (this.state.search_by_label != 0) {
      cnt = cnt + 1;
    }
    this.setState({
      nb_sort_active: cnt,
    });
  };

  resetSort = (e) => {
    /*-------------ADD NEW FILTER HERE--------------*/
    e.preventDefault();
    this.setState({
      search_by_entity: "Entités",
      select_entity_id: null,
      search_by_nineBox: "Nine Box",
      search_by_skill: "Skill",
      select_gender: "Homme/Femme",
      search_gender: null,
      search_by_label: "0",
      filter: null,
      nb_sort_active: 0,
    });
  };

  render() {
    if (this.state.isConnect === false) {
      return <Loader />;
    }
    if (this.state.talentsStatusCode === 204) {
      return (
        <div>
          <Navbar />
          <ToolsBar />
          <div className="home-container">
            <NotFoundTalents />
          </div>
        </div>
      );
    }
    /*-------------- default render ---------------*/
    return (
      <div>
        <Navbar />
        <ToolsBar />
        <div className="home-container">
          <button
            id="filterHome"
            onClick={() => {
              $(".search-block").slideToggle();
            }}
          >
            <i className="icon">&#xe919;</i> Filtrer
          </button>
          {/* ----------- Search Box ------------- */}
          <div className="search-block">
            <form>
              <p className="title-searh">
                Filtres{" "}
                <a onClick={(e) => this.resetSort(e)} href="/">
                  Réinitialiser
                </a>
              </p>
              <input
                onChange={this.onFilterChange}
                name="filter"
                type="search"
              />
              <div className="search-by-cat">
                <div>
                  <p>Classement Ninebox</p>
                  <p>{this.state.search_by_nineBox}</p>
                </div>
                <ul className="dropdown-of-search" id="search_nineBox">
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "A1",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    A1
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "A2",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    A2
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "A3",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    A3
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "B1",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    B1
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "B2",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    B2
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "B3",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    B3
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "C1",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    C1
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "C2",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    C2
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        search_by_nineBox: "C3",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_nineBox").hide();
                    }}
                  >
                    C3
                  </li>
                </ul>
                <div
                  onClick={() => {
                    $("#search_nineBox").slideToggle();
                  }}
                >
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <img src={editIcon} alt="icon d'édition" />
                  </a>
                </div>
              </div>
              <div className="search-by-cat">
                <div>
                  <p>Entité / Service</p>
                  <p>{this.state.search_by_entity}</p>
                </div>
                <ul className="dropdown-of-search" id="search_entity">
                  {this.state.entities.map((entity) => (
                    <li
                      onClick={() => {
                        this.setState({
                          search_by_entity: entity.name,
                          select_entity_id: entity.id,
                        });
                        setTimeout(() => {
                          this.cntFilter();
                        }, 100);
                        $("#search_entity").hide();
                      }}
                    >
                      {entity.name}
                    </li>
                  ))}
                </ul>
                <div
                  onClick={() => {
                    $("#search_entity").slideToggle();
                  }}
                  id="change_dropdown_entity"
                >
                  <a href="#">
                    <img src={editIcon} alt="icon d'édition" />
                  </a>
                </div>
              </div>

              <div className="search-by-cat">
                <div>
                  <p>Genre</p>
                  <p>{this.state.select_gender}</p>
                </div>
                <ul className="dropdown-of-search" id="search_gender">
                  <li
                    onClick={() => {
                      this.setState({
                        select_gender: "Homme",
                        search_gender: "man",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_gender").hide();
                    }}
                  >
                    Homme
                  </li>
                  <li
                    onClick={() => {
                      this.setState({
                        select_gender: "Femme",
                        search_gender: "woman",
                      });
                      setTimeout(() => {
                        this.cntFilter();
                      }, 100);
                      $("#search_gender").hide();
                    }}
                  >
                    Femme
                  </li>
                </ul>
                <div
                  onClick={() => {
                    $("#search_gender").slideToggle();
                  }}
                  id="change_dropdown_entity"
                >
                  <a href="#">
                    <img src={editIcon} alt="icon d'édition" />
                  </a>
                </div>
              </div>

              <div className="search-by-cat">
                <div>
                  <p>Soft skills</p>
                  <p>{this.state.search_by_skill}</p>
                </div>
                <ul className="dropdown-of-search" id="search_skill">
                  {this.state.softSkills.map((skill) => (
                    <li
                      onClick={() => {
                        this.setState({ search_by_skill: skill.name });
                        setTimeout(() => {
                          this.cntFilter();
                        }, 100);
                        $("#search_skill").hide();
                      }}
                    >
                      {skill.name}
                    </li>
                  ))}
                </ul>
                <div
                  onClick={() => {
                    $("#search_skill").slideToggle();
                  }}
                  id="change_dropdown_skill"
                >
                  <a>
                    <img src={editIcon} />
                  </a>
                </div>
              </div>
              <br />
              <div
                className="search-by-label d-flex"
                id="responsability_label"
                onClick={() => {
                  this.setState({ search_by_label: "1" });
                  setTimeout(() => {
                    this.cntFilter();
                  }, 100);
                }}
              >
                <img src={responsability} alt="Plus de responsabilités" />
                <p>Plus de responsabilités</p>
              </div>
              <div
                className="search-by-label d-flex"
                id="mobility_label"
                onClick={() => {
                  this.setState({ search_by_label: "2" });
                  setTimeout(() => {
                    this.cntFilter();
                  }, 100);
                }}
              >
                <img src={mobility} alt="Plus de mobilité" />
                <p>Plus de mobilité</p>
              </div>
              <div
                className="search-by-label d-flex"
                id="follow_label"
                onClick={() => {
                  this.setState({ search_by_label: "3" });
                  setTimeout(() => {
                    this.cntFilter();
                  }, 100);
                }}
              >
                <img src={follow} alt="À suivre de près " />
                <p>À suivre de près </p>
              </div>
            </form>
          </div>
          <Result
            talents={this.state.talents}
            entities={this.state.entities}
            sort_entity={this.state.select_entity_id}
            sort_nineBox={this.state.search_by_nineBox}
            sort_skill={this.state.search_by_skill}
            gender={this.state.search_gender}
            sort_label={this.state.search_by_label}
            filter={this.state.filter}
            nb_sort_active={this.state.nb_sort_active}
          ></Result>
        </div>
      </div>
    );
  }
}
