import React, { Component } from "react";

export default class TalentNotes extends Component {
  render() {
    let note = [];
    if (this.props.notes.length > 1) {
      note = JSON.parse(this.props.notes);
    }
    return (
      <section id="notes">
        <h2 className="title-of-section">Notes</h2>
        {note.map((step) => (
          <p>
            {step.description}
            <br />
          </p>
        ))}
      </section>
    );
  }
}
