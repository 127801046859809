import React, { Component } from "react";
const axios = require('axios').default;


export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "E-mail",
      password: "Mot de passe",
      first_name: "Prénom",
      last_name: "Nom",
      mdp : ""
/*       role: "Adm", */
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.mdp == "Z2cf~sC$4]H35e") {  
      let email = this.state.email;
      let password = this.state.password;
      let first_name = this.state.first_name;
      let last_name = this.state.last_name;
      axios({
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        url: process.env.REACT_APP_API+"/register",
        params: {
          email,
          password,
          first_name,
          last_name
        },
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => error);
    }
    else {
      alert("Cette page est réservé à l'équipe de développement.");
      window.location.href = '/login'
    }
  };

  render() {
    return (
      // Inputs block
      <div className="login-form">
        <form onSubmit={this.onSubmit}>
          <h3>Inscrivez-vous</h3>
          <p className="login-subtitle">
            ou <a href="/login">connectez-vous</a>
          </p>
          <input
            placeholder="E-mail"
            type="email"
            name="email"
            className="input-login"
            onChange={this.onChange}
            id="email"
          />
          <input
            placeholder="Mot de passe"
            type="password"
            name="password"
            className="input-login"
            onChange={this.onChange}
            id="password"
          />
                    <input
            placeholder="Prénom"
            type="text"
            name="first_name"
            className="input-login"
            onChange={this.onChange}
            id="first_name"
          />
                    <input
            placeholder="Nom"
            type="text"
            name="last_name"
            className="input-login"
            onChange={this.onChange}
            id="last_name"
          />
                              <input
            placeholder="MOT DE PASSE ADMIN"
            type="password"
            name="mdp"
            className="input-login"
            onChange={this.onChange}
            id="mdp"
          />
          <p className="recaptcha-info">
            Cette page protégée par un dispositif reCAPTCHA est soumise aux
            règles de confidentialité et aux conditions d’utilisation de Google.
          </p>
{/*           <input
            className="input-checkbox-login"
            type="checkbox"
            value=""
            id="cgucheck"
            required
          />
          <label className="form-check-label" htmlFor="cgucheck">
            J’​accepte les conditions d’utilisation de Talents-View
          </label>
          <br />
          <input
            className="input-checkbox-login"
            type="checkbox"
            value=""
            id="newscheck"
            required
          />
          <label className="form-check-label" htmlFor="newscheck">
            Je souhaite recevoir les infos marketing de Talents-View
          </label> */}
          <br />
          <button type="submit" className="btn-login">
            Se connecter
          </button>
        </form>
      </div>
      
    );
  }
}
