import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";

import Navbar from "../../Navbar/Navbar";
import Loader from "../../Loader/Loader";
import ToolsBar from "../../Admin/ToolsBar";

import NavbarAccount from "../Navbar/NavbarAccount";

import "../../../style/account/add-user.css";
const tools = require("../../../fct-tools");
const token = tools.getToken();

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: [""],
      isConnect: false,
      email: "E-mail",
      password: "Mot de passe",
      first_name: "Prénom",
      last_name: "Nom",
      role: 2,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        process.env.REACT_APP_API + "/logged/add-user",
        {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          password: this.state.password,
          key: this.state.account.key,
          role: this.state.role,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data == "done") {
          $("#errorAddTalent").fadeOut(function () {
            $("#successAddTalent").fadeIn();

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
          console.log("result data ", res.data);
        } else {
          console.log("result data ", res.data);
          $("#errorAddTalent").fadeIn();
        }
      })
      .catch((err) => err);
  };

  componentDidMount() {
    const account_data = axios.get(process.env.REACT_APP_API + "/is-logged", {
      headers: { Authorization: `Bearer ${token}` },
    });
    axios
      .all([account_data])
      .then((result) => {
        this.setState({
          account: result[0].data.user.payload,
          isConnect: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    console.log(this.state.account.key);
    if (this.state.account < 1) {
      return <Loader />;
    }
    if (this.state.account.role !== 0) {
      window.location.href = "/";
    }
    return (
      <div>
        <Navbar />
        <ToolsBar />
        <div className="container d-flex account-container">
          <NavbarAccount activePage="add-user" />
          <div className="account-block">
            <div className="success-label" id="successAddTalent">
              <p>Fiche talent ajouté avec succès.</p>
            </div>
            <div className="error-label" id="errorAddTalent">
              <p>Adresse mail déjà utilisée.</p>
            </div>
            <h1>Créer un utilisateur</h1>
            <div className="row-section-account">
              <div className="col-12 ">
                <form className="form-adduser">
                  <div className="col-5">
                    <label htmlFor="first_name">Prénom</label>
                    <input
                      onChange={this.onChange}
                      type="text"
                      name="first_name"
                      id="first_name"
                      placeholder="Jean"
                    />
                  </div>
                  <div className="col-5">
                    <label htmlFor="last_name">Nom</label>
                    <input
                      onChange={this.onChange}
                      type="text"
                      name="last_name"
                      id="first_name"
                      placeholder="Valgens"
                    />
                  </div>
                  <div className="col-9">
                    <label htmlFor="email">Adresse mail</label>
                    <input
                      onChange={this.onChange}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="jean.valgens@gmail.com"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="role">Rôle</label>
                    <select onChange={this.onChange} name="role" id="role">
                      <option value="2">Lecteur</option>
                      <option value="1">Admin</option>
                    </select>
                  </div>
                  <div className="col-5">
                    <label htmlFor="password">Mot de passe</label>
                    <input
                      onChange={this.onChange}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="*******"
                    />
                  </div>
                  <div className="col-12"></div>
                  <div className="col-5">
                    <label htmlFor="confirm_password">
                      Confirmer le mot de passe
                    </label>
                    <input
                      onChange={this.onChange}
                      type="password"
                      name="confirm_password"
                      id="confirm_password"
                      placeholder="*******"
                    />
                  </div>
                  <div className="col-12">
                    <button
                      onClick={this.onSubmit}
                      type="submit"
                      className="primary-action-btn"
                    >
                      Ajouter
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
