import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import Navbar from "../Navbar/Navbar";
import Loader from "../Loader/Loader";
import ToolsBar from "../Admin/ToolsBar";
import Chart from "react-google-charts";
import "../../style/statistical/statistical.css";

const tools = require("../../fct-tools");
const token = tools.getToken();
export default class Statistical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      talents: [],
      talentWithoutSuccessor: 0,
      talentGender: 0,
      talentMan: 0,
      talentWoman: 0,
      entities: [],
      entitiesCountTalents: [],
      nineboxCount: [],
    };
  }
  percentageConvert(number1, number2) {
    let result = Math.round((number1 / number2) * 100);
    return result;
  }
  componentDidMount() {
    axios({
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API + "/logged/show-talents",
    })
      .then((data) => {
        this.setState({ talents: data.data });
        let talentWithoutSuccessor = 0;
        let talentIsMan = 0;
        let talentIsWoman = 0;
        let nineBox = {
          A1: 0,
          A2: 0,
          A3: 0,
          B1: 0,
          B2: 0,
          B3: 0,
          C1: 0,
          C2: 0,
          C3: 0,
        };

        /* ---------- CHIFFRE CLEF ---------- */
        for (const key in data.data) {
          if (data.data[key].gender === "man") {
            talentIsMan++;
          }
          if (data.data[key].gender === "woman") {
            talentIsWoman++;
          }
          const talentGender =
            (talentIsMan / (talentIsMan + talentIsWoman)) * 100;
          this.setState({ talentGender: Math.round(talentGender) });

          this.setState({ talentMan: talentIsMan, talentWoman: talentIsWoman });

          if (this.state.talentGender < 50) {
            $("#manNumber").css({
              width: this.state.talentGender + "%",
              zIndex: 1,
            });
            $("#womanNumber").css({
              width: 100 - this.state.talentGender + "%",
              zIndex: 0,
            });
          } else if (this.state.talentGender === 0) {
            $("#manNumber").css({
              display: "none",
            });
            $("#womanNumber").css({
              width: this.state.talentGender + "%",
            });
          } else if (this.state.talentGender === 100) {
            $("#manNumber").css({
              width: this.state.talentGender + "%",
            });
            $("#womanNumber").css({
              display: "none",
            });
          } else {
            $("#manNumber").css({
              minWidth: this.state.talentGender + "%",
              zIndex: 0,
            });
            $("#womanNumber").css({
              minWidth: 100 - this.state.talentGender + "%",
              zIndex: 1,
            });
          }

          if (data.data[key].succeed === "[]") {
            talentWithoutSuccessor++;
            this.setState({ talentWithoutSuccessor: talentWithoutSuccessor });
          }
          switch (data.data[key].nine_box) {
            case "A1":
              nineBox.A1++;
              break;
            case "A2":
              nineBox.A2++;
              break;
            case "A3":
              nineBox.A3++;
              break;
            case "B1":
              nineBox.B1++;
              break;
            case "B2":
              nineBox.B2++;
              break;
            case "B3":
              nineBox.B3++;
              break;
            case "C1":
              nineBox.C1++;
              break;
            case "C2":
              nineBox.C2++;
              break;
            case "C3":
              nineBox.C3++;
              break;

            default:
              break;
          }
          this.setState({ nineboxCount: nineBox });
        }

        axios({
          method: "GET",
          withCredentials: true,
          url: process.env.REACT_APP_API + "/logged/show-entities",
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((res) => {
            this.setState({ entities: res.data });

            /* ---------- GRAPHIQUES ---------- */

            const talentsInEntities = [["Entité", "Talents"]];
            for (const key in res.data) {
              let countTalents = 0;

              for (const index in this.state.talents) {
                if (res.data[key].id === this.state.talents[index].id_entity) {
                  countTalents++;
                }
              }
              talentsInEntities.push([res.data[key].name, countTalents]);
              this.setState({ entitiesCountTalents: talentsInEntities });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/login";
      });
  }
  render() {
    if (
      this.state.data === null ||
      this.state.data === [] ||
      this.state.entity === null
    ) {
      return <Loader />;
    }
    return (
      <div>
        <Navbar />
        <ToolsBar />
        <div className="container" id="statistical">
          <div className="statistical-number">
            <div>
              <h2>{this.state.talents.length}</h2>
              <p>talents {/* <strong>(+2%)</strong> */}</p>
            </div>
            <div>
              <h2>{this.state.talentWithoutSuccessor}</h2>
              <p>talents sans successeurs</p>
            </div>
            <div>
              <div id="manNumber">
                <h2>{this.state.talentMan}</h2>
                <p>hommes</p>
              </div>
              <div id="womanNumber">
                <h2>{this.state.talentWoman}</h2>
                <p>femmes</p>
              </div>
            </div>
          </div>
          <div className="statistical-graphic">
            <div>
              <h2>talents par entité</h2>
              <Chart
                width={"100%"}
                height={"25vh"}
                chartType="PieChart"
                loader={<h4>Chargement en cours...</h4>}
                data={this.state.entitiesCountTalents}
                options={{
                  pieHole: 0.3,
                  pieSliceText: "none",
                  animation: {
                    duration: 1000,
                    easing: "out",
                    startup: true,
                  },
                }}
              />
            </div>
            <div>
              <h2>répartition des talents</h2>
              <table id="nineboxGraphic">
                <tbody>
                  <tr>
                    <td>
                      <h1>A1</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.A1,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                    <td>
                      <h1>A2</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.A2,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                    <td>
                      <h1>A3</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.A3,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h1>B1</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.B1,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                    <td>
                      <h1>B2</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.B2,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                    <td>
                      <h1>B3</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.B3,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h1>C1</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.C1,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                    <td>
                      <h1>C2</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.C2,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                    <td>
                      <h1>C3</h1>
                      <p>
                        {this.percentageConvert(
                          this.state.nineboxCount.C3,
                          this.state.talents.length
                        )}
                        %
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <Chart
                width={"100%"}
                height={"25vh"}
                chartType="BarChart"
                loader={<h4>Chargement en cours...</h4>}
                data={[
                  ["Classement ninebox", "Ninebox", { role: "style" }],
                  ["A1", this.state.nineboxCount.A1, "#3366cc"],
                  ["A2", this.state.nineboxCount.A2, "#5b8bea"],
                  ["A3", this.state.nineboxCount.A3, "#8bacec"],
                  ["B1", this.state.nineboxCount.B1, "#dc3912"],
                  ["B2", this.state.nineboxCount.B2, "#e65e3e"],
                  ["B3", this.state.nineboxCount.B3, "#ec9581"],
                  ["C1", this.state.nineboxCount.C1, "#ff9900"],
                  ["C2", this.state.nineboxCount.C2, "#eeb053"],
                  ["C3", this.state.nineboxCount.C3, "#ecc58a"],
                ]}
                options={{
                  colors: ["#3366cc", "#ffab91"],
                  hAxis: {
                    title: "Nombre de Talents",
                    minValue: 0,
                  },
                  vAxis: {},
                  animation: {
                    duration: 1000,
                    easing: "out",
                    startup: true,
                  },
                  legend: { position: "none" },
                }}
                // For tests
                rootProps={{ "data-testid": "1" }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
