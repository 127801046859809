import React, { Component } from "react";
import TalentHeader from "./talentContent/TalentHeader";
import TalentNotes from "./talentContent/TalentNotes";
import TalentProjects from "./talentContent/TalentProjects";
import TalentSkills from "./talentContent/TalentSkills";
import TalentSuccessors from "./talentContent/TalentSuccessors";

export default class TalentContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.setState({
      data: JSON.parse(this.props.data),
    });
  }

  render() {
    if (this.state.data === null || this.state.data === {}) {
      return <div>loading...</div>;
    }
    const data = this.state.data;
    return (
      <div id="talent-content-container">
        <TalentHeader
          first_name={data.first_name}
          last_name={data.last_name}
          job={data.job}
          birthday={data.birthday}
          nineBox={data.nine_box}
          entity={this.props.entity}
          adr={data.address}
        ></TalentHeader>
        <TalentSkills
          hardSkills={data.hard_skill}
          softSkills={data.soft_skill}
        ></TalentSkills>
        <TalentProjects projects={data.projects}></TalentProjects>
        <TalentNotes notes={data.notes}></TalentNotes>
        <TalentSuccessors
          succeed={data.succeed}
          may_succeed={data.may_succeed}
        ></TalentSuccessors>
      </div>
    );
  }
}
