import React, { Component } from "react";
import Informations from "./Informations";
import LoginForm from "./LoginForm";
import "../../style/login/login.css";

export default class Login extends Component {
  render() {
    return (
      // login global page
      <div className="login-container">
        <Informations></Informations>
        <LoginForm></LoginForm>
      </div>
    );
  }
}
