//import axios from "axios";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import "../../style/home/result.css";
import moreResponsability from "../../img/icons/more-responsability.svg";
import moreMobility from "../../img/icons/more-mobility.svg";
import follow from "../../img/icons/follow.svg";

export default class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
    };
  }

  syncEntity = (te_id) => {
    if (this.props.entities === undefined) {
      console.log("Loading ...");
    } else {
      for (let i = 0; i < this.props.entities.length; i++) {
        if (this.props.entities[i].id === te_id) {
          return this.props.entities[i].name;
        }
      }
    }
  };

  sortingApp = (nb_sort_active) => {
    //Don't forget to add cmd to the reset function in Home.jsx

    const talents = this.props.talents;
    let talents_sorted = [];

    /* Bool verify if the sorting had already been executed -> 1 for every sorting type */
    let boolEnt = false;
    let boolBox = false;
    let boolSkill = false;
    let boolGender = false;
    let boolLabel = false;

    for (let i = 0; i < nb_sort_active; i++) {
      //<----- Begin
      /*-------------ADD NEW FILTER HERE--------------*/

      /* ------ Sort by entity ------ */
      if (this.props.sort_entity > 0 && boolEnt == false) {
        if (talents_sorted.length > 0) {
          let cache = [];
          for (let j = 0; j < talents_sorted.length; j++) {
            if (
              typeof talents_sorted[j] !== "undefined" &&
              talents_sorted[j].id_entity == this.props.sort_entity
            )
              cache.push(talents_sorted[j]);
          }
          talents_sorted = cache;
          boolEnt = true;
        } else {
          for (let j = 0; j < talents.length; j++) {
            if (talents[j].id_entity === this.props.sort_entity)
              talents_sorted.push(talents[j]);
          }
          boolEnt = true;
        }
      }

      /*------ Sort by Nine Box ------ */
      if (this.props.sort_nineBox !== "Nine Box" && boolBox == false) {
        if (talents_sorted.length > 0) {
          let cache = [];
          for (let j = 0; j < talents_sorted.length; j++) {
            if (
              typeof talents_sorted[j] !== "undefined" &&
              talents_sorted[j].nine_box == this.props.sort_nineBox
            ) {
              cache.push(talents_sorted[j]);
            }
          }
          talents_sorted = cache;
          boolBox = true;
        } else {
          for (let j = 0; j < talents.length; j++) {
            if (talents[j].nine_box === this.props.sort_nineBox) {
              talents_sorted.push(talents[j]);
            }
          }
          boolBox = true;
        }
      }

      /* ------ Sort by skill ------ */
      if (this.props.sort_skill !== "Skill" && boolSkill == false) {
        if (talents_sorted.length > 0) {
          let cache = [];
          let arraySkill = [];
          for (let j = 0; j < talents_sorted.length; j++) {
            arraySkill.push(JSON.parse(talents_sorted[j].soft_skill));
            let skillsNames = [];
            if (arraySkill[j].length > 0) {
              for (let cnt = 0; cnt < arraySkill[j].length; cnt++) {
                skillsNames.push(arraySkill[j][cnt].name);
              }
              for (const key in skillsNames) {
                if (skillsNames[key] == this.props.sort_skill) {
                  cache.push(talents_sorted[j]);
                }
              }
            }
          }
          talents_sorted = cache;
          boolSkill = true;
        } else {
          let arraySkill = [];
          for (let j = 0; j < talents.length; j++) {
            arraySkill.push(JSON.parse(talents[j].soft_skill));
            let skillsNames = [];
            if (arraySkill[j].length > 0) {
              for (let cnt = 0; cnt < arraySkill[j].length; cnt++) {
                skillsNames.push(arraySkill[j][cnt].name);
              }
              for (const key in skillsNames) {
                if (skillsNames[key] == this.props.sort_skill) {
                  talents_sorted.push(talents[j]);
                }
              }
            }
          }
          boolSkill = true;
        }
      }

      /*------ Sort by Gender ------ */
      if (this.props.gender !== null && boolGender == false) {
        if (talents_sorted.length > 0) {
          let cache = [];
          for (let j = 0; j < talents_sorted.length; j++) {
            if (
              typeof talents_sorted[j] !== "undefined" &&
              talents_sorted[j].gender == this.props.gender
            ) {
              cache.push(talents_sorted[j]);
            }
          }
          talents_sorted = cache;
          boolGender = true;
        } else {
          for (let j = 0; j < talents.length; j++) {
            if (talents[j].gender === this.props.gender) {
              talents_sorted.push(talents[j]);
            }
          }
          boolGender = true;
        }
      }

      /*------ Sort by Label ------ */
      if (this.props.sort_label !== "0" && boolLabel == false) {
        if (talents_sorted.length > 0) {
          let cache = [];
          for (let j = 0; j < talents_sorted.length; j++) {
            if (
              typeof talents_sorted[j] !== "undefined" &&
              talents_sorted[j].label == this.props.sort_label
            ) {
              cache.push(talents_sorted[j]);
            }
          }
          talents_sorted = cache;
          boolLabel = true;
        } else {
          for (let j = 0; j < talents.length; j++) {
            if (talents[j].label === this.props.sort_label) {
              talents_sorted.push(talents[j]);
            }
          }
          boolLabel = true;
        }
      }
    } //<--------------------------------------- End
    return talents_sorted;
  };

  labelSwitch(label) {
    switch (label) {
      case "0":
        return;
      case "1":
        return (
          <img
            className="label-of-talent"
            src={moreResponsability}
            alt="Plus de responsabilités"
          />
        );
      case "2":
        return (
          <img
            className="label-of-talent"
            src={moreMobility}
            alt="Plus de mobilité"
          />
        );
      case "3":
        return (
          <img
            className="label-of-talent"
            src={follow}
            alt="À suivre de près"
          />
        );

      default:
        return;
    }
  }
  render() {
    let talents_sorted = this.props.talents;
    if (this.props.filter != null) {
      talents_sorted = this.props.talents.filter((talent) => {
        let name = talent.first_name + " " + talent.last_name;
        return name.toLowerCase().includes(this.props.filter.toLowerCase());
      });
    }
    if (
      (this.props.nb_sort_active !== 0) &
      (this.props.nb_sort_active !== undefined)
    ) {
      talents_sorted = this.sortingApp(this.props.nb_sort_active);
    }

    //console.log(talents_sorted);

    return (
      <div className="result-block">
        {talents_sorted.map((talent) => (
          <div className="talent-container">
            <div>
              {this.labelSwitch(talent.label)}
              <Link to={`/talent/${talent.id}`}>
                <img
                  className="picture-of-talent"
                  src={process.env.REACT_APP_API + "/storage/" + talent.url_pic}
                  alt="fake img"
                />
              </Link>
            </div>
            <div>
              <h3>{talent.nine_box}</h3>
              <h4>
                {talent.last_name} {talent.first_name}
              </h4>
              <h5>{talent.job}</h5>
              <p>{this.syncEntity(talent.id_entity)}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
