import React, { Component } from "react";

export default class TalentSkills extends Component {
  render() {
    let hardSkills = [];
    let softSkills = [];
    if (this.props.hardSkills.length > 1) {
      hardSkills = JSON.parse(this.props.hardSkills);
    }
    if (this.props.softSkills.length > 1) {
      softSkills = JSON.parse(this.props.softSkills);
    }

    return (
      <section id="skills">
        <h2 className="title-of-section">COMPÉTENCES</h2>
        <div>
          <div>
            <h4>HARD SKILLS</h4>
            {hardSkills.map((step) => (
              <div className="skill-bar">
                <div skill={step.level}></div>
                <p>{step.name}</p>
              </div>
            ))}
          </div>
          <div>
            <h4>SOFT SKILLS</h4>
            <ul>
              {softSkills.map((step) => (
                <li>{step.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}
